import styled from '@emotion/styled'
import { Button, Form } from 'antd'

export const FormItemSubmit = styled(Form.Item)`
  display: flex;
  justify-content: end;
  margin-top: 20px;
  .save-draft {
    margin-right: 20px;
  }
`
export const UpMedia = styled(Button)`
  display: flex;
  align-items: center;
  padding: 10px;
  font-weight: bold;
`
export const Header = styled.div`
  margin-bottom: 16px;
`

export const Wrap = styled.div`
  overflow: auto;
`

export const UploadContent = styled.div`
  .upload-icon {
    margin-right: 8px;
  }
  .required {
    margin-right: 8px;
    color: ${({ theme }) => theme.colors.danger};
  }
  .loading-icon {
    margin-left: 8px;
  }
`

export const WrapPublishDate = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
`
