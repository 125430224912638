import { PropsWithChildren, ReactNode, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { ROUTERS, storage } from '@defines'
import { userSelector } from '@store'

export type Options = {
  checkVerify?: boolean
  autoRedirect?: boolean
  nonAuthen?: boolean
}

type Props = PropsWithChildren & {
  options?: Options
}

const Container: React.FC<Props> = ({ children, options }) => {
  const { location } = window
  const navigate = useNavigate()
  const userInfo = useSelector(userSelector.selectData)

  const [searchParams] = useSearchParams()
  const listSeach = searchParams.toString()
  const accessToken = localStorage.getItem(storage.ACCESS_TOKEN)

  useEffect(() => {
    const search = new URLSearchParams(location.search)
    const redirect = search.get('redirect')
    // const isVerified = accessToken ? parseJwt(accessToken)?.verified : null;
    const isVerified = true
    const isLoginPage = location.pathname === ROUTERS.LOGIN
    const isRegisterPage = location.pathname === ROUTERS.REGISTER
    const isVerifyPage = location.pathname?.includes(ROUTERS.VERIFY)

    if (accessToken && !isLoginPage && !isVerifyPage && !isVerified) {
      navigate(ROUTERS.VERIFY)
      return
    }

    if (accessToken && !isLoginPage) {
      return
    }
    if (redirect && accessToken) {
      navigate(String(redirect)?.replaceAll(';', '&'))
      return
    }

    if (accessToken && (isLoginPage || isRegisterPage) && isVerified) {
      navigate(ROUTERS.HOME)
      return
    }

    if (!accessToken && !options?.nonAuthen && !isLoginPage) {
      const defaultRedirect = `${location.pathname?.replaceAll(ROUTERS.NOT_FOUND, '')}?${listSeach?.replaceAll(
        '&',
        ';'
      )}`
      navigate(`${ROUTERS.LOGIN}?redirect=${redirect ? redirect : defaultRedirect}`)
      return
    }
    if (options?.autoRedirect) {
      // handle auto later
      return
    }
  }, [userInfo])

  return <>{children}</>
}

export const useRouteRender = (node: ReactNode, options?: Options) => {
  return <Container options={options}>{node}</Container>
}
