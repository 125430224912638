import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { api } from '@api'
import { appActions, userActions } from '@store'
import { clearAllStorage } from '@utils'
import { ROUTERS } from '@defines'

export const useLogout = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const cleanStore = () => {
    clearAllStorage()
    api.removeToken()
    dispatch(userActions.clean())
    dispatch(appActions.clean())
    navigate(ROUTERS.LOGIN)
  }

  const logout = async () => {
    try {
      setLoading(true)
      api.removeToken()
      cleanStore()
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return { logout, loading }
}
