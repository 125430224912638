import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'
import type { TableProps } from 'antd'
import { Button, Modal, Switch, Table } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Actions, CustomTagList, Title } from '@components'
import { ROUTERS } from '@defines'
import { useListCareer } from '@hooks'
import { GetWPCommonQuery, TagModel } from '@types'
import { formatDateUS2 } from '@utils'

import { Header } from './styles'

const { confirm } = Modal

export const Careers = () => {
  const navigate = useNavigate()
  const { getListCareer, listCareer, deleteCareer, loading } = useListCareer()

  const handleGetListTag = (params?: GetWPCommonQuery) => {
    getListCareer({ ...params })
  }

  useEffect(() => {
    handleGetListTag({ page: 1, limit: 10 })
  }, [])

  const columns: TableProps<any>['columns'] = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: text => <div>{text}</div>
    },
    {
      title: 'Skills',
      dataIndex: 'skills',
      key: 'skills',
      render: (_, { skills }) => (
        <>
          <CustomTagList tags={skills?.split(/[,;]/)?.map((i: string) => ({ name: i, id: i })) || []} />
        </>
      )
    },
    {
      title: 'Vacancy',
      dataIndex: ['summary', 'vacancy'],
      key: 'vacancy',
      render: text => <div>{text}</div>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => <div>{text}</div>
    },
    {
      title: 'Salary',
      dataIndex: ['summary', 'salary'],
      key: 'salary',
      render: text => <div>{text}</div>
    },
    {
      title: 'PublishOn',
      dataIndex: ['summary', 'publishOn'],
      key: 'publishOn',
      render: publishOn => {
        return formatDateUS2(publishOn)
      }
    },
    {
      title: 'Deadline',
      dataIndex: ['summary', 'deadline'],
      key: 'deadline',
      render: deadline => {
        return formatDateUS2(deadline)
      }
    },
    {
      title: 'FullEnergy',
      dataIndex: 'status',
      key: 'status',
      render: status => (
        <Switch
          size='default'
          disabled
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={status === '1'}
        />
      )
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      render: (_, record) => (
        <Actions
          loading={loading}
          actions={{
            onEdit: () => {
              navigate(ROUTERS.CAREER_DETAIL?.replace(':careerId', record.id))
            },
            onDelete: async () => {
              confirm({
                title: 'Confirm Delete?',
                content: 'Are you sure to procee this?',
                onOk() {
                  deleteCareer(record.id)
                }
              })
            }
          }}
        />
      )
    }
  ]

  const data =
    listCareer?.result?.map(item => ({
      ...item
    })) || []

  const handleTableChange: TableProps<TagModel>['onChange'] = pagination => {
    handleGetListTag({
      page: pagination.current || 1,
      limit: pagination.pageSize || 10
    })
  }

  return (
    <div>
      <Header>
        <Button type='primary' onClick={() => navigate(ROUTERS.CAREER_CREATION)}>
          Create new <PlusOutlined />
        </Button>
      </Header>
      <Title>List careers</Title>
      <Table
        columns={columns}
        scroll={{ x: 1800 }}
        dataSource={data as any}
        loading={loading}
        pagination={{
          current: Number(listCareer?.page) || 1,
          pageSize: Number(listCareer?.limit) || 10,
          total: Number(listCareer?.count) || 0
        }}
        onChange={handleTableChange}
      />
    </div>
  )
}
