import { RollbackOutlined } from '@ant-design/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Col, Form, Row } from 'antd'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

import { CustomInput, CustomSelect, ModalIndustryCreation, Title } from '@components'
import { ROUTERS } from '@defines'
import { useController, useListIndustries, useProjectsActions } from '@hooks'

import { FormItemSubmit, Header } from './styles'

export const DetailProjects = () => {
  const { projectId } = useParams()
  const { createProject, getDetailProject, detail, clearDetail, loading, updateProject } = useProjectsActions()
  const { t } = useTranslation()
  const { controller } = useController()
  const navigate = useNavigate()
  const { getListIndustries, industryOptions } = useListIndustries()

  const schema = yup.object().shape({
    name: yup.string().trim().required(t('message.error_required')),
    project_industries: yup.array().of(yup.number()).notRequired(),
    used_technologies: yup.string().nullable().notRequired()
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    mode: 'onBlur',
    defaultValues: {},
    resolver: yupResolver(schema)
  })

  const handleForm = async (formData: any) =>
    controller(async () => {
      const params = {
        ...formData,
        project_industries: formData?.project_industries?.length
          ? formData?.project_industries?.map((i: string) => Number(i))
          : []
      }
      const onSuccess = () => {
        navigate(ROUTERS.PROJECT)
      }
      if (projectId) {
        updateProject(projectId, params, { onSuccess })
      } else {
        createProject({ ...params }, { onSuccess })
      }
    })

  useEffect(() => {
    getListIndustries({ limit: 999 })
  }, [])

  useEffect(() => {
    // setValues("title",)
    if (projectId) {
      getDetailProject(projectId)
    }
    return () => {
      clearDetail()
    }
  }, [projectId])

  useEffect(() => {
    if (detail) {
      setValue('name', detail?.name || '')
      setValue('project_industries', detail?.industries?.map(i => Number(i?.id)) || [])
      setValue('used_technologies', detail?.used_technologies || '')
    }
  }, [detail])

  const onSubmit = handleSubmit(handleForm)

  const filterOption = (input: string, option?: { label: string; value: number }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  return (
    <div>
      <Header>
        <Button onClick={() => navigate(ROUTERS.PROJECT)}>
          <RollbackOutlined />
          Back
        </Button>
      </Header>
      <Title>Detail Project</Title>
      <div>
        <Form
          name='normal_login'
          className='login-form'
          initialValues={{
            remember: true
          }}
          onFinish={onSubmit}
        >
          <Row gutter={[20, 20]}>
            <Col xs={8}>
              <CustomInput
                control={control}
                type='string'
                name='name'
                placeholder='Name'
                label='Name'
                required
                maxLength={500}
                error={errors.name?.message}
                size='large'
                mb={12}
              />
            </Col>
            <Col xs={8}>
              <CustomSelect
                id='project_industries'
                control={control}
                label='Industries'
                size='large'
                options={industryOptions}
                filterOption={filterOption as any}
                showSearch
                mb={16}
                mode='multiple'
                name={`project_industries`}
                placeholder='Select industries'
              />
            </Col>
            <ModalIndustryCreation />
            <Col xs={8}>
              <CustomInput
                control={control}
                type='string'
                name='used_technologies'
                label={t('Technologies')}
                placeholder={t('Enter technologies')}
                error={errors.used_technologies?.message}
                maxLength={100}
                radius={8}
                size='large'
              />
            </Col>
          </Row>
          <FormItemSubmit>
            <Button type='primary' size='large' loading={loading} htmlType='submit' className='save-draft'>
              Save
            </Button>
          </FormItemSubmit>
        </Form>
      </div>
    </div>
  )
}
