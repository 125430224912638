'use client'
import { message } from 'antd'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { careersApi } from '@api'
import { careersActions, careersSelector } from '@store'
import { GetWPCommonQuery } from '@types'

import { useController } from '../app'

export const useListCareer = () => {
  const dispatch = useDispatch()
  const listCareer = useSelector(careersSelector.selectCareers)
  const detail = useSelector(careersSelector.selectCareerDetail)
  const currentQuery = useSelector(careersSelector.selectListCareersQueryParams)

  const { controller } = useController()

  const [loading, setLoading] = useState(false)

  const getListCareer = async (params: GetWPCommonQuery) => {
    controller(
      async () => {
        setLoading(true)
        const res = await careersApi.getListCareer({ ...currentQuery, ...params })

        dispatch(careersActions.setCareers(res?.data))
        dispatch(careersActions.setCareersQuery({ ...currentQuery, ...params }))
        setLoading(false)
      },
      {
        onLoading: setLoading
      }
    )
  }

  const deleteCareer = async (id: string, options?: { preventGetList?: boolean; onSuccess?: () => void }) => {
    controller(
      async () => {
        await careersApi.deleteCareer(id)
        if (!options?.preventGetList) {
          getListCareer({ ...currentQuery, page: 1 })
        }
        message.success('Delete career successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }

  return { listCareer, deleteCareer, loading, currentQuery, detail, getListCareer }
}
