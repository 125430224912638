import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { GetWPCommonQuery, FieldModel, FieldsResponse } from '@types'

import { State } from './type'

const initialState: State = {
  listFields: undefined,
  detailField: undefined,
  listFieldsQueryParams: { limit: 10, page: 1 }
}

export const slice = createSlice({
  name: 'fields',
  initialState,
  reducers: {
    clean: () => {},
    setFields: (state, action: PayloadAction<FieldsResponse>) => {
      state.listFields = action.payload
    },
    setFieldDetail: (state, action: PayloadAction<FieldModel | undefined>) => {
      state.detailField = action.payload
    },
    setFieldsQuery: (state, action: PayloadAction<GetWPCommonQuery>) => {
      state.listFieldsQueryParams = action.payload
    }
  }
})

// > Export
// * Action
export const actions = { ...slice.actions }
// * Reducer
export const { reducer } = slice
