import styled from '@emotion/styled'
import { Modal, ModalProps } from 'antd'
import { ReactNode } from 'react'

import { OverflowLoading } from '../OverflowLoading'

type Props = ModalProps & {
  left?: ReactNode
  right?: ReactNode
  loading?: boolean
  height?: number
  borderColor?: string
  closeIcon?: ReactNode
}

const Section = styled.div`
  flex: 1;
  overflow-y: auto;
`

const Content = styled.div<{ height?: number }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${({ height }) => height || 220}px;
  & > ${Section}:not(:last-child) {
    margin-right: 10px;
  }
`

const WrapModal = styled(Modal)<ModalProps & { borderColor?: string }>`
  .ant-modal-content {
    border-color: ${({ theme, borderColor }) => (borderColor ? borderColor : theme.colors.primary100)} !important;
    padding: 16px 24px;
  }
  .ant-modal-footer {
    margin-top: 0px;
  }
`

export const DialogChartData: React.FC<Props> = ({
  left,
  closeIcon,
  borderColor,
  right,
  loading,
  height,
  ...props
}) => {
  return (
    <WrapModal {...props} centered footer closable={closeIcon ? false : true} borderColor={borderColor}>
      {closeIcon && closeIcon}
      <Content height={height}>
        {loading ? (
          <OverflowLoading />
        ) : (
          <>
            <Section>{left}</Section>
            {right && <Section>{right}</Section>}
          </>
        )}
      </Content>
    </WrapModal>
  )
}
