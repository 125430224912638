import { AliasToken, SeedToken } from 'antd/es/theme/internal'

export const colorsDefines = {
  primary100: '#F6D053',
  primary200: '#292E30',
  secondary100: '#879CB3',
  secondary200: '#E7ECEF',
  neutral: '#3f3f3f',
  grey: '#000000',
  grey900: '#303537',
  grey800: '#292E30',
  grey700: '#373C3E',
  grey600: '#636366',
  grey500: '#8E8E93',
  grey400: '#AEAEB2',
  grey300: '#C7C7CC',
  grey200: '#D1D1D6',
  grey100: '#F5F5F5',
  grey50: '#F9F9FB',
  grey10: '#FFFFFF',
  grey11: '#C2C2C2',
  info: '#2B8EFF',
  success: '#32D74B',
  actionSucess: '#34B53A',
  green: '#3ea04d',
  actionDanger: '#FF3A29',
  warning: '#FFB441',
  danger: '#FF453A',
  placeholder: '#E9EDF2',
  skeleton: '#F0F1F1',
  backgroundlight: '#F2F2F250',
  backgrounddark: '#F0F1F150',
  divider: '#F0F1F1',
  blur400: '#F2F2F2',
  blur300: '#F2F2F2',
  blur200: '#F2F2F2',
  blur100: '#F2F2F2',
  blur400dark: '#1A1A1C',
  blur300dark: '#1A1A1C',
  blur200dark: '#1A1A1C',
  blur100dark: '#1A1A1C',
  'palettes1.1': '#4A55A2',
  'palettes1.2': '#7895CB',
  'palettes1.3': '#A0BFE0',
  'palettes1.4': '#C5DFF8',
  'palettes2.1': '#645CBB',
  'palettes2.2': '#A084DC',
  'palettes2.3': '#BFACE2',
  'palettes2.4': '#EBC7E6',
  'palettes3.1': '#6096B4',
  'palettes3.2': '#93BFCF',
  'palettes3.3': '#BDCDD6',
  'palettes3.4': '#EEE9DA',
  black: '#000',
  white: '#fff',
  purple: '#9747FF',
  pink: '#FFA5CB',
  organge: '#EF8636',
  blue: '#539FEC',
  red: '#BB1910'
}

export const colors: typeof colorsDefines & Partial<SeedToken> & Partial<AliasToken> & Record<string, string> = {
  ...colorsDefines,
  colorPrimary: colorsDefines.primary100,
  colorInfo: colorsDefines.info,
  colorSuccess: colorsDefines.success,
  colorWarning: colorsDefines.warning,
  colorError: colorsDefines.danger,
  colorBgContainer: colorsDefines.primary200,
  colorBorder: colorsDefines.grey400,
  colorBgSpotlight: colorsDefines.primary100,
  colorTextBase: colorsDefines.grey10,
  colorTextPlaceholder: colorsDefines.grey600,
  colorLink: `${colorsDefines.primary100}50`,
  colorLinkHover: `${colorsDefines.primary100}`,
  colorLinkActive: `${colorsDefines.primary100}`
}

export type ColorsDefinesKey = keyof typeof colorsDefines
