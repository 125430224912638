import { ClusterDataType, FileBlobModel, NetworkingDataType, RepoModel } from '@types'

import { api, Api } from './config'

class Migration {
  instance: Api

  constructor(params: Api) {
    this.instance = params
  }

  public getMigrationRequest = (repoId: string) => {
    return this.instance.get(`/api/repository/${repoId}`)
  }

  public getFileDetailRequest = (repoId: string) => {
    return this.instance.get(`/api/blob/${repoId}`) as any as Promise<FileBlobModel>
  }

  public postMigrationRequest = (payload: { url: string; token: string }) => {
    return this.instance.post('api/repository/', payload) as any as Promise<RepoModel>
  }

  public getClusterChartDataRequest = (repoId: string) => {
    return this.instance.get<ClusterDataType>(`/api/repository/${repoId}/clustering`)
  }

  public getGraphChartDataRequest = (repoId: string) => {
    return this.instance.get<NetworkingDataType>(`/api/repository/${repoId}/copy_graph`)
  }
}

export const migrationApi = new Migration(api)
