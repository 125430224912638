import styled from '@emotion/styled'
import { Select, SelectProps } from 'antd'
import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: ${({ theme }) => theme.colors.grey};
  .required {
    margin-right: 8px;
    color: ${({ theme }) => theme.colors.danger};
  }
`

const Wrap = styled.div<{ mb?: number }>`
  margin-bottom: ${({ mb }) => `${mb}px` || 0};
  .err {
    color: ${({ theme }) => theme.colors.danger};
    font-size: 12px;
    margin: 4px 8px;
  }
  .ant-select-lg {
    min-height: 42px;
    .ant-select-selector {
      min-height: 42px !important;
    }
  }
  .ant-select-selector {
    height: 100%;
  }
`

const StyledSelect = styled(Select)<SelectProps & { isError: boolean }>`
  width: 100%;
  text-align: left;
  .ant-select-selector {
    border-color: ${({ theme, isError }) => (isError ? theme.colors.danger : theme.colors.grey500)} !important;
  }
  .ant-select-selection-placeholder {
    color: ${({ theme }) => theme.colors.grey500};
    font-size: 14px;
  }
  .ant-select-selection-item {
    font-size: 14px;
  }
`

type Props = {
  label?: string
  error?: string
  control?: Control<any>
  name: string
  valueProps?: string
  onChangeValues?: (e: any) => void
  mb?: number
  required?: boolean
  hanleReachEnd?: () => void
  id?: string
}

export const CustomSelect: FC<SelectProps & Props> = ({
  label,
  control,
  options,
  name,
  onChangeValues,
  valueProps,
  disabled,
  error,
  mb,
  size,
  placeholder,
  required,
  mode,
  showSearch,
  onSearch,
  filterOption,
  hanleReachEnd,
  id
}) => {
  const renderSelect = ({ value, onChange, onBlur }: any) => {
    return (
      <Wrap mb={mb}>
        <Label>
          {required && <span className='required'>*</span>}
          {label}
        </Label>
        <StyledSelect
          mode={mode}
          id={id}
          value={value ? value : undefined}
          onChange={onChange}
          options={options}
          onBlur={onBlur}
          disabled={disabled}
          placeholder={placeholder}
          size={size}
          isError={error ? true : false}
          showSearch={showSearch}
          onSearch={onSearch}
          filterOption={filterOption as any}
          onPopupScroll={e => {
            const { target } = e
            if ((target as any).scrollTop + (target as any).offsetHeight === (target as any).scrollHeight) {
              hanleReachEnd?.()
            }
          }}
        />
        <div className='err'>{error}</div>
      </Wrap>
    )
  }
  if (!control) {
    return renderSelect({ value: valueProps, onChange: onChangeValues })
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, onBlur } }) => renderSelect({ value, onChange, onBlur })}
    />
  )
}
