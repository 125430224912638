import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CommentsResponse, GetWPCommonQuery } from '@types'

import { State } from './type'

const initialState: State = {
  listComments: undefined,
  listCommentsQueryParams: { limit: 10, page: 1 }
}

export const slice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    clean: () => {},
    setComments: (state, action: PayloadAction<CommentsResponse>) => {
      state.listComments = action.payload
    },
    setCommentsQuery: (state, action: PayloadAction<GetWPCommonQuery>) => {
      state.listCommentsQueryParams = action.payload
    }
  }
})

// > Export
// * Action
export const actions = { ...slice.actions }
// * Reducer
export const { reducer } = slice
