import { PlusOutlined } from '@ant-design/icons'
import type { TableProps, TabsProps } from 'antd'
import { Button, Empty, Form, Modal, Table, Tabs, message } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'

import { Actions, CustomTagList, StatusBadge, Title } from '@components'
import { PostStatus, ROUTERS } from '@defines'
import { useListPosts, useListPostsCategory } from '@hooks'
import { GetWPCommonQuery, PostModel } from '@types'
import { formatDateUS2 } from '@utils'

import { Filter, OrderModal } from './components'
import { Header } from './styles'

type TDraggableTableRowProps = React.HTMLAttributes<HTMLTableRowElement> & {
  index: number
  record: PostModel
}

type TDroppableTableBodyProps = React.HTMLAttributes<HTMLTableSectionElement>

const { confirm } = Modal

export const Posts = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const currentParams = Object.fromEntries([...searchParams])
  const [currentRecord, setCurrentRecord] = useState<PostModel | null>(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [form] = Form.useForm()
  const {
    getListPosts,
    chooseOrder,
    listPosts,
    currentQuery,
    clearFilter,
    publishPost,
    makeAsDraftPost,
    deletePost,
    loading
  } = useListPosts()
  const catId = searchParams?.get?.('catId')
  const [activeTab, setActiveTab] = useState(catId || 'All')
  const [tableData, setTableData] = useState<PostModel[]>([])
  const { listCategory, getListCategory } = useListPostsCategory()

  const orderOptions = useMemo(() => {
    const currentPage = listPosts?.page || 1
    const limit = listPosts?.limit || 10
    const totalPrevItems = Number(currentPage * limit) - 10
    const itemsPrev = totalPrevItems
      ? new Array(totalPrevItems)?.fill(0)?.map((_i, index) => ({
          value: index + 1,
          label: index + 1
        }))
      : []
    const listCurrentOrder =
      listPosts?.result.map((post, index) => ({
        value: Number(post.order),
        label: Number(itemsPrev?.length || 0) + Number(index) + 1
      })) || []
    return itemsPrev?.concat(listCurrentOrder)
  }, [listPosts])

  const categoryTabs =
    listCategory?.result?.map(item => ({
      key: item.id?.toString(),
      label: item.name
    })) || []

  const handleGetListPost = (params?: GetWPCommonQuery) => {
    if (catId) {
      if (catId !== activeTab) {
        setActiveTab(catId)
      }
      getListPosts({
        ...params,
        category_ids: catId ? [catId] : []
      })
    } else {
      getListPosts({
        ...params,
        category_ids: catId ? [catId] : []
      })
    }
  }

  useEffect(() => {
    if (activeTab) {
      handleGetListPost({ page: 1, limit: 10, sort: '+order' })
    }
  }, [activeTab])

  useEffect(() => {
    if (listPosts?.result) {
      setTableData([...listPosts?.result])
    }
  }, [listPosts?.result])

  useEffect(() => {
    getListCategory({
      limit: 100
    })
    return () => {
      clearFilter()
    }
  }, [])

  const navigateKeepParams = (pathname: string) => {
    navigate({ pathname, search: `?${createSearchParams(currentParams)}` })
  }

  const showOrderModal = (record: PostModel) => {
    setCurrentRecord(record)
    setIsModalVisible(true)
  }

  const items: TabsProps['items'] = [
    {
      key: 'All',
      label: 'All'
    }
  ]?.concat(categoryTabs)

  const columns: TableProps<PostModel>['columns'] = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      render: (item, record, index) => ((currentQuery?.page || 1) - 1) * (currentQuery?.limit || 10) + index + 1
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '25%',
      render: text => <div>{text}</div>
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'categories',
      width: 200,
      render: (_, { categories }) => (
        <>
          <CustomTagList tags={categories?.map(i => ({ name: i.name, id: i.id }))} />
        </>
      )
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      render: (_, { tags }) => (
        <>
          <CustomTagList tags={tags?.map(i => ({ name: i.name, id: String(i.id) })) || []} />
        </>
      )
    },
    {
      title: 'Publish date',
      dataIndex: 'publish_date',
      key: 'publish_date',
      render: createdAt => formatDateUS2(createdAt)
    },
    {
      title: 'Created Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: createdAt => formatDateUS2(createdAt)
    },
    {
      title: 'Views',
      dataIndex: 'views',
      key: 'views'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => <StatusBadge status={status} />
    },
    {
      title: 'Action',
      align: 'center',
      key: 'action',
      fixed: 'right',
      render: (_, record) => (
        <Actions
          loading={loading}
          actions={{
            onEdit: () => {
              navigateKeepParams(ROUTERS.POST_DETAIL?.replace(':postId', record.id))
            },
            onDelete: async () => {
              confirm({
                title: 'Confirm Delete?',
                content: 'Are you sure to procee this?',
                onOk() {
                  deletePost(record.id)
                }
              })
            },
            onPublish:
              Number(record.status) === PostStatus.Draft
                ? async () => {
                    confirm({
                      title: 'Publish Post?',
                      content: 'Are you sure to publish this post?',
                      onOk() {
                        publishPost(record.id, { preventGetList: true })
                      }
                    })
                  }
                : undefined,
            makeAsDraft:
              Number(record.status) === PostStatus.Publish
                ? async () => {
                    confirm({
                      title: 'Make as draft for Post?',
                      content: 'Are you sure to make as draft this post?',
                      onOk() {
                        makeAsDraftPost(record.id, { preventGetList: true })
                      }
                    })
                  }
                : undefined,
            onOrder: () => {
              showOrderModal(record)
            }
          }}
        />
      )
    }
  ]

  const DroppableTableBody = (props: TDroppableTableBodyProps) => {
    return (
      <Droppable droppableId='post-droppable' type='group'>
        {provided => (
          <>
            <tbody ref={provided.innerRef} {...props} {...provided.droppableProps}></tbody>
            <tfoot>{provided.placeholder}</tfoot>
          </>
        )}
      </Droppable>
    )
  }

  const DraggableTableRow = ({ index, record, ...props }: TDraggableTableRowProps) => {
    if (!record) {
      return (
        <tr className='ant-table-placeholder row-item' {...props}>
          <td colSpan={columns.length} className='ant-table-cell'>
            <div className='ant-empty ant-empty-normal'>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          </td>
        </tr>
      )
    }

    return (
      <Draggable key={`draggable-${record.slug}`} draggableId={record.slug} index={index}>
        {provided => (
          <tr
            key={`tr-${record.slug}`}
            ref={provided.innerRef}
            {...props}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className='row-item'
          />
        )}
      </Draggable>
    )
  }

  const handleOrderOk = async (values: { order: number }) => {
    if (!currentRecord) return

    await chooseOrder({
      order: {
        id: currentRecord.id,
        ...values
      }
    })

    setIsModalVisible(false)
  }

  const handleOrderCancel = () => {
    setCurrentRecord(null)
    setIsModalVisible(false)
  }

  const onDragEnd = (result: DropResult) => {
    try {
      const { destination, source } = result

      if (!destination || result.reason === 'CANCEL') {
        return
      }
      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return
      }

      const newTableData = Array.from(tableData)
      const { order: destinationOrder } = tableData[destination.index]

      const [removed] = newTableData.splice(source.index, 1)
      newTableData.splice(destination.index, 0, removed)
      setTableData(newTableData)

      chooseOrder({
        order: {
          order: destinationOrder,
          id: removed.id
        }
      })
    } catch (error) {
      message.success(`Something went wrong`)
    }
  }

  const onChange = (key: string) => {
    setActiveTab(key)
    if (key !== 'All') {
      navigate({
        search: `${createSearchParams({
          catId: key
        })}`
      })
    } else {
      delete currentParams.catId
      navigateKeepParams('')
    }
  }

  const handleTableChange: TableProps<PostModel>['onChange'] = pagination => {
    handleGetListPost({
      page: pagination.current || 1,
      limit: pagination.pageSize || 10
    })
  }

  return (
    <div>
      <Header>
        <Button type='primary' onClick={() => navigateKeepParams(ROUTERS.POST_CREATION)}>
          Create new <PlusOutlined />
        </Button>
      </Header>
      <Tabs activeKey={activeTab} items={items} onChange={onChange} />
      <Title>List {categoryTabs?.find(i => i.key === activeTab)?.label || 'all'} posts</Title>
      <Filter onSearch={params => getListPosts({ ...params, page: 1 })} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Table
          columns={columns}
          dataSource={tableData}
          loading={loading}
          scroll={{ x: 1400 }}
          components={{
            body: {
              wrapper: (val: TDroppableTableBodyProps) =>
                DroppableTableBody({
                  ...val
                }),
              row: (val: TDraggableTableRowProps) =>
                DraggableTableRow({
                  ...val
                })
            }
          }}
          onRow={(record, index) => {
            return {
              index,
              record,
              onClick: () => null
            }
          }}
          pagination={{
            current: Number(listPosts?.page) || 1,
            pageSize: Number(listPosts?.limit) || 10,
            total: Number(listPosts?.count) || 0
          }}
          onChange={handleTableChange}
        />
      </DragDropContext>

      <OrderModal
        visible={isModalVisible}
        onCancel={handleOrderCancel}
        onOk={handleOrderOk}
        currentOrder={currentRecord?.order}
        orders={orderOptions}
        form={form}
      />
    </div>
  )
}
