import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Col, Form, Modal, Row } from 'antd'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import styled from '@emotion/styled'

import { CustomInput } from '@components'
import { DEFAULT_INDUSTRIES } from '@defines'
import { useController, useIndustriesActions, useListIndustries } from '@hooks'
import { FormItemSubmit } from '@pages/login/styles'
import { IndustryCreationParams } from '@types'

const Wrap = styled.div`
  display: flex;
  height: 100%;
  margin-left: 16px;
  margin-top: 23px;
`

export const ModalIndustryCreation: React.FC = () => {
  const [open, setOpen] = useState(false)
  const { createIndustry, detail, loading } = useIndustriesActions()
  const { getListIndustries } = useListIndustries()
  const { t } = useTranslation()
  const { controller } = useController()

  const schema = yup.object().shape({
    name: yup.string().trim().required(t('message.error_required'))
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    mode: 'onBlur',
    defaultValues: {},
    resolver: yupResolver(schema)
  })

  const handleCancel = () => {
    setOpen(false)
  }

  const handleForm = async (formData: IndustryCreationParams) =>
    controller(async () => {
      const onSuccess = () => {
        // navigate(ROUTERS.TAGS)
        getListIndustries({ limit: 999 })
        handleCancel()
      }
      createIndustry({ ...formData }, { onSuccess })
    })

  useEffect(() => {
    if (detail) {
      setValue('name', detail?.name || '')
    }
  }, [detail])

  const onSubmit = handleSubmit(handleForm)

  const showModal = () => {
    setOpen(true)
  }

  return (
    <Wrap>
      <Button type='primary' size='large' onClick={showModal}>
        Create new industry
      </Button>
      <Modal width={600} open={open} title='Tag creation' onCancel={handleCancel} footer={false}>
        <Form
          name='normal_login'
          className='login-form'
          initialValues={{
            remember: true
          }}
          onFinish={onSubmit}
        >
          <Row gutter={[20, 20]}>
            <Col xs={24}>
              <CustomInput
                control={control}
                type='string'
                name='name'
                placeholder='Name'
                label='Name'
                required
                disabled={DEFAULT_INDUSTRIES?.includes(String(detail?.name))}
                maxLength={500}
                error={errors.name?.message}
                size='large'
                mb={12}
              />
            </Col>
          </Row>
          <FormItemSubmit>
            <Button type='primary' loading={loading} size='large' htmlType='submit' className='save-draft'>
              Save
            </Button>
          </FormItemSubmit>
        </Form>
      </Modal>
    </Wrap>
  )
}
