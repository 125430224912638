import qs from 'query-string'

import { GetWPCommonQuery, Pagination, FieldCreationParams, FieldModel } from '@types'

import { api, Api } from './config'

class Handler {
  private instance!: Api

  constructor(apiInstance: Api) {
    this.instance = apiInstance
  }

  private get caller() {
    return this.instance.caller
  }

  public getListFields = async (params: GetWPCommonQuery) => {
    const queryString = params ? qs.stringify(params) : ''
    return this.caller.get<Pagination<FieldModel>>(`/field?${queryString}`)
  }

  public createNewField = async (params: FieldCreationParams) => {
    return this.caller.post(`/field`, params)
  }

  public updateField = async (id: string, params: FieldCreationParams) => {
    return this.caller.patch(`/field/${id}`, params)
  }

  public deleteField = async (id: string) => {
    return this.caller.delete(`/field/${id}`)
  }

  public getDetailField = async (id: string) => {
    return this.caller.get(`/field/${id}`)
  }
}

export const fieldsApi = new Handler(api)
