import { PostModel } from '@types'

export const FIXED_PERMANENT_LINK = {
  NEWS: `${process.env.REACT_APP_MAIN_PAGE}/news`,
  PUBLICATION: `${process.env.REACT_APP_MAIN_PAGE}/ai-by-fpt-software/publication-by-aic`,
  TECH_BLOGS: `${process.env.REACT_APP_MAIN_PAGE}/ai-by-fpt-software/tech-blog`,
  WEBINARS: `${process.env.REACT_APP_MAIN_PAGE}/ai-by-fpt-software/webinars-and-workshops`
}

export enum PostStatus {
  Draft,
  Publish,
  Disabled
}
// export enum CategoryStatus {
//   Active,
//   InActive
// }

export const defaultSocials: PostModel['social_links'] = {
  facebook: 'https://www.facebook.com/aic.fsoft?mibextid=LQQJ4d',
  linkin: 'https://www.linkedin.com/company/fpt-software-ai-center/',
  github: '',
  download: ''
}

export enum CategoriesFixed {
  Publication = 'Publication',
  Techblogs = 'Techblogs',
  News = 'News',
  'Webinars & Workshops' = 'Webinars & Workshops',
  'Media coverage' = 'Media coverage',
  'Project AI' = 'Project AI'
}

export const DefaultCategory = [
  CategoriesFixed.Publication,
  CategoriesFixed.Techblogs,
  CategoriesFixed.News,
  CategoriesFixed['Webinars & Workshops'],
  CategoriesFixed['Media coverage'],
  CategoriesFixed['Project AI']
] as string[]

export const DEFAULT_INDUSTRIES = [
  'Manufacturing/Automotive/Transportation',
  'Public/Utilities',
  'Healthcare',
  'Retail/Logistics/Marketing',
  'Finance/Insurance/Banking',
  'Productivity tool',
  'Leisure/Entertainment',
  'Construction/Real estate',
  'Others'
]
