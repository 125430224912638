'use client'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { commentsApi } from '@api'
import { commentsActions, commentsSelector } from '@store'
import { GetWPCommonQuery } from '@types'

import { useController } from '../app'

export const useListComments = () => {
  const dispatch = useDispatch()
  const listComments = useSelector(commentsSelector.selectComments)
  const currentQuery = useSelector(commentsSelector.selectListCommentsQueryParams)

  const { controller } = useController()

  const [loading, setLoading] = useState(false)

  const getListComments = async (params: GetWPCommonQuery) => {
    controller(
      async () => {
        setLoading(true)
        const res = await commentsApi.getListComments({ ...currentQuery, ...params })

        dispatch(commentsActions.setComments(res?.data))
        dispatch(commentsActions.setCommentsQuery({ ...currentQuery, ...params }))
        setLoading(false)
      },
      {
        onLoading: setLoading
      }
    )
  }

  return { listComments, loading, currentQuery, getListComments }
}
