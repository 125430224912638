'use client'
import { message } from 'antd'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { postApi } from '@api'
import { postsActions, postsSelector } from '@store'
import { PostCategoryCreationForm } from '@types'

import { useController } from '../app'

export const usePostCategoryActions = () => {
  const dispatch = useDispatch()
  const detail = useSelector(postsSelector.selectCategoryDetail)
  const { controller } = useController()

  const [loading, setLoading] = useState(false)

  const createCategory = async (params: PostCategoryCreationForm, options?: { onSuccess?: () => void }) => {
    controller(
      async () => {
        await postApi.createNewCategory(params)
        message.success('Created category successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }
  const updateCategory = async (id: string, params: PostCategoryCreationForm, options?: { onSuccess?: () => void }) => {
    controller(
      async () => {
        await postApi.updateCategory(id, params)
        message.success('Updated category successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }

  const getDetailCategory = async (id: string, options?: { onSuccess?: () => void }) => {
    controller(
      async () => {
        const res = await postApi.getDetailPostCategory(id)

        dispatch(postsActions.setDetailCategory(res?.data))
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }

  const clearDetail = () => {
    dispatch(postsActions.setDetailCategory(undefined))
  }

  return { loading, detail, clearDetail, getDetailCategory, createCategory, updateCategory }
}
