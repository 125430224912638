import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

// Extend dayjs with utc plugin
dayjs.extend(utc)

export const formatDateUS = (date?: string | Date) => {
  if (!date) {
    return ''
  }
  return dayjs(date).format('DD.MM.YYYY')
}

export const formatDateLocal = (date?: string | Date) => {
  if (!date) {
    return ''
  }
  return new Date(date).toLocaleString()
}

export const formatDateUS2 = (date?: string | Date) => {
  if (!date) {
    return ''
  }
  return dayjs(date).format('HH:mm:ss DD/MM/YYYY')
}

export const formatSimpleDate = (date?: string | Date) => {
  if (!date) {
    return ''
  }
  return dayjs(date).format('YYYY/MM/DD')
}

export const formatDateUsWithDayName = (date: string) => {
  if (!date) {
    return ''
  }
  return dayjs(date).format('dddd HH:mm A')
}

export const timeAgo = (timestamp: string) => {
  if (!timestamp) {
    return
  }
  const currentDate: Date = new Date()
  const pastDate: Date = new Date(timestamp)
  const timeDifference = currentDate.getTime() - pastDate.getTime()
  const seconds = Math.floor(timeDifference / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const weeks = Math.floor(days / 7)

  if (seconds < 60) {
    return `${seconds} ${seconds === 1 ? 'second' : 'seconds'} ago`
  } else if (minutes < 60) {
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`
  } else if (hours < 24) {
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`
  } else if (days < 7) {
    return `${days} ${days === 1 ? 'day' : 'days'} ago`
  } else {
    return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago`
  }
}

export const checkTimeExpired = (inputTime: string) => {
  // Parse the input time string to get the Day.js object in UTC
  const targetTime = dayjs.utc(inputTime)

  // Get the current time in UTC
  const currentTime = dayjs.utc()

  // Calculate the difference in seconds
  const timeDifferenceInSeconds = targetTime.diff(currentTime, 'second')

  // Check if the time difference is less than or equal to 0 seconds
  return timeDifferenceInSeconds >= 60
}

export const getTimeExpired = (inputTime: string) => {
  // Parse the input time string to get the Day.js object in UTC
  const targetTime = dayjs.utc(inputTime)

  // Get the current time in UTC
  const currentTime = dayjs.utc()

  // Calculate the difference in seconds
  const timeDifferenceInSeconds = targetTime.diff(currentTime, 'second')

  // Check if the time difference is less than or equal to 0 seconds
  return timeDifferenceInSeconds
}

export const formatDateApi = (date: Date) => {
  return dayjs(date).utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
}
