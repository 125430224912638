import { ReactComponent as ArrowLeftOutlineSvg } from '@assets/svg/arrow-left-outline.svg'
import { ReactComponent as CircleOutlineSvg } from '@assets/svg/circle-outline.svg'
import dotImg from '@assets/svg/dot.svg'
import { ReactComponent as RectangleSvg } from '@assets/svg/rectangle-outline.svg'
import rectangleImg from '@assets/svg/rectangle.svg'
import { ReactComponent as StarSvg } from '@assets/svg/star-ouline.svg'
import starImg from '@assets/svg/star.svg'
import { ReactComponent as TritangleSvg } from '@assets/svg/tritangle-outline.svg'
import tritangleImg from '@assets/svg/tritangle.svg'
import unknowImg from '@assets/svg/unknow-icon.svg'
import { ReactComponent as UnknowSvg } from '@assets/svg/unknow-outline.svg'
import { colors } from '@style'

export const useGraph = () => {
  const LEGENDS = [
    {
      id: 'program',
      icon: <RectangleSvg />,
      iconLink: rectangleImg,
      name: 'Program',
      backgroundColor: colors.purple
    },
    {
      id: 'copy',
      icon: <StarSvg />,
      iconLink: starImg,
      name: 'Copy',
      backgroundColor: colors.pink
    },
    {
      id: 'db',
      icon: <CircleOutlineSvg />,
      iconLink: dotImg,
      name: 'Database',
      backgroundColor: colors.organge
    },
    {
      id: 'screen',
      icon: <TritangleSvg />,
      iconLink: tritangleImg,
      name: 'Screen',
      backgroundColor: colors.blue
    },
    {
      id: 'link',
      icon: <ArrowLeftOutlineSvg />,
      name: 'Arrow',
      backgroundColor: colors.primary100
    },
    {
      id: '',
      icon: <UnknowSvg />,
      iconLink: unknowImg,
      name: 'Unknown',
      backgroundColor: colors.red
    }
  ]

  return {
    legend: LEGENDS
  }
}
