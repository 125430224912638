import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import { Response } from '@types'
import { RESPONSE_CODE, storage } from '@defines'

export class Api {
  public caller!: AxiosInstance

  static instance?: Api

  static getInstance() {
    if (!this.instance) this.instance = new Api()
    return this.instance
  }

  constructor() {
    this.init()
  }

  private init() {
    this.caller = axios.create()
    // - Token
    const token = localStorage.getItem(storage.ACCESS_TOKEN) || sessionStorage.getItem(storage.ACCESS_TOKEN)
    // eslint-disable-next-line
    if (token) this.caller.defaults.headers.common['Authorization'] = `Bearer ${token}`
    // - Config
    this.caller.defaults.baseURL = process.env.REACT_APP_API_URL
    this.caller.defaults.timeout = 1000 * 60 * 5
  }

  //> Handle
  public getAuthorizationToken = () => this.caller.defaults.headers.common.Authorization

  public isAuthorization = () => Boolean(this.getAuthorizationToken())

  public setToken(token: string, options?: { saveToken?: boolean; pushChannel?: boolean }) {
    if (options?.saveToken) localStorage.setItem(storage.ACCESS_TOKEN, token)
    // eslint-disable-next-line
    if (token) this.caller.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }

  public removeToken() {
    localStorage.removeItem(storage.ACCESS_TOKEN)
    // eslint-disable-next-line
    this.caller.defaults.headers.common['Authorization'] = ''
  }

  // > Controller
  private controller: <T = any>(handle: () => Promise<AxiosResponse<Response<T>>>) => Promise<Response<T>> =
    async handle => {
      try {
        const response = await handle()
        return response.data
      } catch (error) {
        // eslint-disable-next-line
        console.log('Error', (error as any)?.response?.data?.resCode)

        if (error instanceof AxiosError) {
          const { response } = error
          const status: number | undefined = response?.status
          const message: RESPONSE_CODE = response?.data?.message
          // eslint-disable-next-line
          console.log('Axios error', status, message)
        }
        throw error
      }
    }

  // > Request
  public get<T = any, D = any>(url: string, apiConfig?: AxiosRequestConfig<D>): Promise<Response<T>> {
    // eslint-disable-next-line
    console.log('[GET]', url)
    return this.controller(() => this.caller.get(url, apiConfig))
  }

  public delete<T = any, D = any>(url: string, apiConfig?: AxiosRequestConfig<D>): Promise<Response<T>> {
    // eslint-disable-next-line
    console.log('[DELETE]', url)
    return this.controller(() => this.caller.delete(url, apiConfig))
  }

  public post<T = any, D = any>(url: string, data?: D, apiConfig?: AxiosRequestConfig<D>): Promise<Response<T>> {
    // eslint-disable-next-line
    console.log('[POST]', url)
    return this.controller(() => this.caller.post(url, data, apiConfig))
  }

  public put<T = any, D = any>(url: string, data?: D, apiConfig?: AxiosRequestConfig<D>): Promise<Response<T>> {
    // eslint-disable-next-line
    console.log('[PUT]', url)
    return this.controller(() => this.caller.put(url, data, apiConfig))
  }

  public patch<T = any, D = any>(url: string, data?: D, apiConfig?: AxiosRequestConfig<D>): Promise<Response<T>> {
    // eslint-disable-next-line
    console.log('[PATCH]', url)
    return this.controller(() => this.caller.patch(url, data, apiConfig))
  }
}

export const api = Api.getInstance()
