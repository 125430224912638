import { Route, Routes } from 'react-router-dom'

import { workspace } from '@defines'
import MainLayout from '@layouts/MainLayout'
import { Comments } from '@pages/comments'
import { DetailFields, Fields } from '@pages/fields'
import HomePage from '@pages/home'
import { DetailIndustries, Industries } from '@pages/industry'
import { Categories, DetailCategory, DetailPost, Posts } from '@pages/posts'
import { DetailTags, Tags } from '@pages/tags'
import { DetailProjects, Projects } from '@pages/projects'
import { Careers, DetailCareer } from '@pages/career'

export const WorkspaceRouter: React.FC = () => {
  return (
    <MainLayout>
      <Routes>
        <Route index element={<HomePage />} />
        <Route path={workspace.POSTS} element={<Posts />} />
        <Route path={workspace.POST_DETAIL} element={<DetailPost />} />
        <Route path={workspace.POST_CREATION} element={<DetailPost />} />
        <Route path={workspace.POST_CATEGORY} element={<Categories />} />
        <Route path={workspace.POST_CATEGORY_DETAIL} element={<DetailCategory />} />
        <Route path={workspace.POST_CATEGORY_CREATION} element={<DetailCategory />} />
        <Route path={workspace.TAGS} element={<Tags />} />
        <Route path={workspace.TAGS_DETAIL} element={<DetailTags />} />
        <Route path={workspace.TAGS_CREATION} element={<DetailTags />} />
        <Route path={workspace.CAREERS} element={<Careers />} />
        <Route path={workspace.CAREER_DETAIL} element={<DetailCareer />} />
        <Route path={workspace.CAREER_CREATION} element={<DetailCareer />} />
        <Route path={workspace.COMMENTS} element={<Comments />} />
        <Route path={workspace.FIELDS} element={<Fields />} />
        <Route path={workspace.FIELDS_DETAIL} element={<DetailFields />} />
        <Route path={workspace.FIELDS_CREATION} element={<DetailFields />} />
        <Route path={workspace.PROJECT} element={<Projects />} />
        <Route path={workspace.PROJECT_DETAIL} element={<DetailProjects />} />
        <Route path={workspace.PROJECT_CREATION} element={<DetailProjects />} />
        <Route path={workspace.INDUSTRY} element={<Industries />} />
        <Route path={workspace.INDUSTRY_DETAIL} element={<DetailIndustries />} />
        <Route path={workspace.INDUSTRY_CREATION} element={<DetailIndustries />} />
      </Routes>
    </MainLayout>
  )
}
