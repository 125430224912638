import styled from '@emotion/styled'
import { Form, Input, InputProps } from 'antd'
import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'

export const Label = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  .required {
    margin-right: 8px;
    color: ${({ theme }) => theme.colors.danger};
  }
`

export const WrapForm = styled(Form.Item)<{ radius?: number; mb?: number }>`
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '22px')} !important;
  .ant-input-affix-wrapper {
    height: 42px;
  }
  input {
    &::placeholder {
      opacity: 1; /* Firefox */
      font-size: 14px;
    }

    &::-ms-input-placeholder {
      /* Edge 12-18 */
    }
  }
  .ant-form-item-explain-error {
    text-align: left;
    font-size: 12px;
    margin: 4px 0px;
  }
`

type Props = {
  label?: string
  error?: string
  control?: Control<any>
  name: string
  valueProps?: string
  onChangeValues?: (e: string) => void
  radius?: number
  mb?: number
}

export const CustomInput: FC<InputProps & Props> = ({
  prefix,
  label,
  error,
  control,
  type,
  placeholder,
  maxLength,
  name,
  onChangeValues,
  valueProps,
  radius,
  mb,
  size,
  required,
  ...props
}) => {
  const renderInput = ({ value, onChange, onBlur }: any) => {
    return (
      <div>
        <Label>
          {required && <span className='required'>*</span>}
          {label}
        </Label>
        <Input
          prefix={prefix}
          className={`custom-input-size-${size}`}
          type={type}
          placeholder={placeholder || ''}
          maxLength={maxLength || 100}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          size={size}
          name={name}
          {...props}
        />
      </div>
    )
  }
  if (!control) {
    return renderInput({ value: valueProps, onChange: onChangeValues })
  }
  return (
    <WrapForm name={name} mb={mb} hasFeedback help={error} validateStatus={error ? 'error' : ''} radius={radius}>
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange, onBlur } }) => renderInput({ value, onChange, onBlur })}
      />
    </WrapForm>
  )
}
