'use client'
import { message } from 'antd'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { unitsApi } from '@api'
import { unitsActions, unitsSelector } from '@store'
import { GetWPCommonQuery } from '@types'

import { useController } from '../app'

export const useListIndustries = () => {
  const dispatch = useDispatch()
  const listIndustries = useSelector(unitsSelector.selectIndustries)
  const detail = useSelector(unitsSelector.selectIndustryDetail)
  const currentQuery = useSelector(unitsSelector.selectListIndustriesQueryParams)

  const { controller } = useController()

  const [loading, setLoading] = useState(false)

  const getListIndustries = async (params: GetWPCommonQuery) => {
    controller(
      async () => {
        setLoading(true)
        const res = await unitsApi.getListIndustries({ ...currentQuery, ...params })

        dispatch(unitsActions.setIndustries(res?.data))
        dispatch(unitsActions.setIndustriesQuery({ ...currentQuery, ...params }))
        setLoading(false)
      },
      {
        onLoading: setLoading
      }
    )
  }

  const deleteIndustries = async (id: string, options?: { preventGetList?: boolean; onSuccess?: () => void }) => {
    controller(
      async () => {
        await unitsApi.deleteIndustry(id)
        if (!options?.preventGetList) {
          getListIndustries({ ...currentQuery, page: 1 })
        }
        message.success('Delete industry successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }

  const industryOptions = listIndustries?.result?.map(i => ({
    label: i.name,
    value: i.id
  }))

  return { listIndustries, industryOptions, deleteIndustries, loading, currentQuery, detail, getListIndustries }
}
