import { PlusOutlined } from '@ant-design/icons'
import type { TableProps } from 'antd'
import { Button, Modal, Table } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Actions, Title } from '@components'
import { DEFAULT_INDUSTRIES, ROUTERS } from '@defines'
import { useListIndustries } from '@hooks'
import { GetWPCommonQuery, IndustryModel } from '@types'

import { Header } from './styles'

const { confirm } = Modal

export const projectIndustry = [
  {
    name: 'Manufacturing/Automotive/Transportation',
    percent: 29.0,
    color: '#2F98E8'
  },
  {
    name: 'Public/Utilities',
    percent: 16.0,
    color: '#FF5879'
  },
  {
    name: 'Healthcare',
    percent: 10.0,
    color: '#42B8B8'
  },
  {
    name: 'Retail/Logistics/Marketing',
    percent: 9.0,
    color: '#FF9538'
  },
  {
    name: 'Finance/Insurance/Banking',
    percent: 7.0,
    color: '#8E5BFF'
  },
  {
    name: 'Productivity tool',
    percent: 10.0,
    color: '#FFC64C'
  },
  {
    name: 'Leisure/Entertainment',
    percent: 3.0,
    color: '#8D5B4C'
  },
  {
    name: 'Construction/Real estate',
    percent: 3.0,
    color: '#EB2F96'
  },
  {
    name: 'Others',
    percent: 13.0,
    color: '#A0D911'
  }
]

export const Industries = () => {
  const navigate = useNavigate()
  const { getListIndustries, listIndustries, deleteIndustries, loading } = useListIndustries()

  const handleGetListIndustry = (params?: GetWPCommonQuery) => {
    getListIndustries({ ...params })
  }

  useEffect(() => {
    handleGetListIndustry({ page: 1, limit: 10 })
  }, [])

  const columns: TableProps<any>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <div>{text}</div>
    },
    // {
    //   title: 'Description',
    //   dataIndex: 'description',
    //   key: 'description'
    // },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   key: 'status',
    //   render: status => (
    //     <Switch
    //       size='default'
    //       disabled
    //       checkedChildren={<CheckOutlined />}
    //       unCheckedChildren={<CloseOutlined />}
    //       checked={status}
    //     />
    //   )
    // },
    {
      title: 'Action',
      width: 200,
      key: 'action',
      render: (_, record) => (
        <Actions
          loading={loading}
          actions={{
            onEdit: () => {
              navigate(ROUTERS.INDUSTRY_DETAIL?.replace(':industryId', record.id))
            },
            onDelete: !DEFAULT_INDUSTRIES?.includes(record?.name)
              ? async () => {
                  confirm({
                    title: 'Confirm Delete?',
                    content: 'Are you sure to proceed this?',
                    onOk() {
                      deleteIndustries(record.id)
                    }
                  })
                }
              : undefined
          }}
        />
      )
    }
  ]

  const data = listIndustries?.result?.map(item => ({
    ...item
  }))

  const handleTableChange: TableProps<IndustryModel>['onChange'] = pagination => {
    handleGetListIndustry({
      page: pagination.current || 1,
      limit: pagination.pageSize || 10
    })
  }

  return (
    <div>
      <Header>
        <Button type='primary' onClick={() => navigate(ROUTERS.INDUSTRY_CREATION)}>
          Create new <PlusOutlined />
        </Button>
      </Header>
      <Title>List Industries</Title>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={{
          current: Number(listIndustries?.page) || 1,
          pageSize: Number(listIndustries?.limit) || 10,
          total: Number(listIndustries?.count) || 0
        }}
        onChange={handleTableChange}
      />
    </div>
  )
}
