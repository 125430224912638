import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { storage } from '@defines'

import { State } from './type'

const initialState: State = {
  accessToken: localStorage.getItem(storage.ACCESS_TOKEN) || null,
  refreshToken: localStorage.getItem(storage.REFRESH_TOKEN) || null,
  clientId: ''
}

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clean: () => initialState,
    setData: (state, action: PayloadAction<any | undefined>) => {
      state.data = action.payload
    },
    setAccessToken: (state, action: PayloadAction<string | undefined>) => {
      state.accessToken = action.payload
    },
    setRefreshToken: (state, action: PayloadAction<string | undefined>) => {
      state.refreshToken = action.payload
    }
  }
})

// > Export
// * Action
export const actions = {
  ...slice.actions
}
// * Reducer
export const { reducer } = slice
