import styled from '@emotion/styled'
import type { CollapseProps } from 'antd'
import { Collapse } from 'antd'
import { FC, useEffect, useState } from 'react'

import { CustomInput } from '@components'
import { defaultSocials } from '@defines'
import { PostModel } from '@types'
import { capitalize } from '@utils'

const Wrap = styled.div`
  margin: 20px 0px;
`

const WrapInput = styled.div`
  margin-bottom: 12px;
`

type Props = {
  defaultValues?: PostModel['social_links']
  onChange?: (values: PostModel['social_links']) => void
}

export const SocialLinks: FC<Props> = ({ defaultValues, onChange }) => {
  const [values, setValues] = useState<PostModel['social_links']>(defaultSocials)
  const onChangeValue = (key: string, value: string) => {
    const changeValues = { ...values, [key]: value }
    setValues(changeValues)
    onChange?.(changeValues)
  }

  useEffect(() => {
    if (defaultValues) {
      setValues(defaultValues)
    }
  }, [defaultValues])

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: <b>Socials Link</b>,
      children: (
        <div>
          {Object.keys(values)
            ?.sort()
            ?.map(key => (
              <WrapInput key={key}>
                <CustomInput
                  type='string'
                  name={key}
                  placeholder={capitalize(key)}
                  label={capitalize(key)}
                  maxLength={100}
                  //   error={errors.author?.message}
                  size='large'
                  value={(values as any)[key]}
                  onChange={e => onChangeValue(key, e.target.value)}
                  mb={12}
                />
              </WrapInput>
            ))}
        </div>
      )
    }
  ]

  return (
    <Wrap>
      <Collapse items={items} />
    </Wrap>
  )
}
