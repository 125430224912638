import styled from '@emotion/styled'
import { Col, Row } from 'antd'
import Search from 'antd/es/input/Search'
import { FC, useEffect, useState } from 'react'

import { useListTags } from '@hooks'
import { CustomSelect } from '@components'

const RowStyled = styled(Row)`
  margin-bottom: 16px;
`

type Props = {
  loading?: boolean
  onSearch?: (params: { search?: string; tag_ids?: string[] }) => void
}

export const Filter: FC<Props> = ({ loading, onSearch }) => {
  const [search, setSearch] = useState('')
  const [tags, setTag] = useState([])
  const { getListTags, tagOptions } = useListTags()

  useEffect(() => {
    getListTags({ limit: 100 })
  }, [])

  return (
    <RowStyled gutter={[20, 20]}>
      <Col xs={12}>
        <Search
          value={search}
          placeholder='Search'
          onChange={e => setSearch(e.target.value)}
          onSearch={() => {
            onSearch?.({
              search,
              tag_ids: tags
            })
          }}
          loading={loading}
          enterButton
        />
      </Col>
      <Col xs={6}>
        <CustomSelect
          value={tags}
          options={tagOptions}
          mode='tags'
          onChangeValues={e => {
            onSearch?.({ tag_ids: e, search })
            setTag(e)
          }}
          mb={16}
          name={`tags`}
          placeholder='Tags'
        />
      </Col>
    </RowStyled>
  )
}
