import { Router } from '@router'
import { useApp, useShowNotification } from '@hooks'
import { GlobalLoading } from '@components'

function App() {
  const { inited } = useApp()
  const contextHolder = useShowNotification()

  if (!inited) return <GlobalLoading />

  return (
    <>
      <Router />
      {contextHolder}
    </>
  )
}

export default App
