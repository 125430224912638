import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Col, Form, Modal, Row, Switch } from 'antd'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import styled from '@emotion/styled'

import { CustomInput, Label } from '@components'
import { useController, useListTags, useTagsActions } from '@hooks'
import { FormItemSubmit } from '@pages/login/styles'
import { TagCreationParams } from '@types'

const Wrap = styled.div`
  display: flex;
  height: 100%;
  margin-left: 16px;
  margin-top: 23px;
`

export const ModalTagCreation: React.FC = () => {
  const [open, setOpen] = useState(false)
  const { createTag, loading, detail } = useTagsActions()
  const { getListTags } = useListTags()
  const { t } = useTranslation()
  const { controller } = useController()

  const schema = yup.object().shape({
    name: yup.string().trim().required(t('message.error_required')),
    description: yup.string().trim().required(t('message.error_required')),
    status: yup.boolean().required(t('message.error_required'))
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      status: true
    },
    resolver: yupResolver(schema)
  })

  const handleCancel = () => {
    setOpen(false)
  }

  const handleForm = async (formData: TagCreationParams) =>
    controller(async () => {
      const onSuccess = () => {
        // navigate(ROUTERS.TAGS)
        getListTags({ limit: 999 })
        handleCancel()
      }
      createTag({ ...formData }, { onSuccess })
    })

  useEffect(() => {
    if (detail) {
      setValue('name', detail?.name || '')
      setValue('description', detail?.description || '')
    }
  }, [detail])

  const onSubmit = handleSubmit(handleForm)

  const showModal = () => {
    setOpen(true)
  }

  return (
    <Wrap>
      <Button type='primary' size='large' onClick={showModal}>
        Create new tag
      </Button>
      <Modal width={800} open={open} title='Tag creation' onCancel={handleCancel} footer={false}>
        <Form
          name='normal_login'
          className='login-form'
          initialValues={{
            remember: true
          }}
          onFinish={onSubmit}
        >
          <Row gutter={[20, 20]}>
            <Col xs={8}>
              <CustomInput
                control={control}
                type='string'
                name='name'
                placeholder='Name'
                label='Name'
                required
                maxLength={500}
                error={errors.name?.message}
                size='large'
                mb={12}
              />
            </Col>
            <Col xs={8}>
              <CustomInput
                control={control}
                type='string'
                name='description'
                placeholder='Description'
                label='Description'
                required
                maxLength={500}
                error={errors.description?.message}
                size='large'
                mb={12}
              />
            </Col>
            <Col xs={8}>
              <Label>Status</Label>
              <Switch
                size='default'
                disabled
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked
              />
            </Col>
          </Row>
          <FormItemSubmit>
            <Button type='primary' size='large' loading={loading} htmlType='submit' className='save-draft'>
              Save
            </Button>
          </FormItemSubmit>
        </Form>
      </Modal>
    </Wrap>
  )
}
