'use client'
import { message } from 'antd'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fieldsApi } from '@api'
import { fieldsActions, fieldsSelector } from '@store'
import { FieldCreationParams } from '@types'

import { useController } from '../app'

export const useFieldsActions = () => {
  const dispatch = useDispatch()
  const detail = useSelector(fieldsSelector.selectFieldDetail)

  const { controller } = useController()

  const [loading, setLoading] = useState(false)

  const createField = async (params: FieldCreationParams, options?: { onSuccess?: () => void }) => {
    controller(
      async () => {
        await fieldsApi.createNewField(params)
        message.success('Created field successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }
  const updateField = async (id: string, params: FieldCreationParams, options?: { onSuccess?: () => void }) => {
    controller(
      async () => {
        await fieldsApi.updateField(id, params)
        message.success('Updated field successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }

  const getDetailField = async (id: string, options?: { onSuccess?: () => void }) => {
    controller(
      async () => {
        const res = await fieldsApi.getDetailField(id)

        dispatch(fieldsActions.setFieldDetail(res?.data))
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }

  const clearDetail = () => {
    dispatch(fieldsActions.setFieldDetail(undefined))
  }

  return { loading, detail, clearDetail, getDetailField, createField, updateField }
}
