import i18next, { NewableModule } from 'i18next'
import { initReactI18next } from 'react-i18next'

import * as langEn from './en.json'
import * as langVi from './vi.json'

const languageDetector: NewableModule<any> | any = {
  type: 'languageDetector',
  async: true,
  detect: (cb: (lang: string) => void) => cb('en'),
  init: () => {},
  cacheUserLanguage: () => {}
}

i18next
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    fallbackLng: 'en',
    debug: false,
    resources: {
      en: { translation: langEn },
      vi: { translation: langVi }
    }
  })
