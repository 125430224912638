'use client'
import { message } from 'antd'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fieldsApi } from '@api'
import { fieldsActions, fieldsSelector } from '@store'
import { GetWPCommonQuery } from '@types'

import { useController } from '../app'

export const useListFields = () => {
  const dispatch = useDispatch()
  const listFields = useSelector(fieldsSelector.selectFields)
  const detail = useSelector(fieldsSelector.selectFieldDetail)
  const currentQuery = useSelector(fieldsSelector.selectListFieldsQueryParams)

  const { controller } = useController()

  const [loading, setLoading] = useState(false)

  const getListFields = async (params: GetWPCommonQuery) => {
    controller(
      async () => {
        setLoading(true)
        const res = await fieldsApi.getListFields({ ...currentQuery, ...params })

        dispatch(fieldsActions.setFields(res?.data))
        dispatch(fieldsActions.setFieldsQuery({ ...currentQuery, ...params }))
        setLoading(false)
      },
      {
        onLoading: setLoading
      }
    )
  }

  const deleteFields = async (id: string, options?: { preventGetList?: boolean; onSuccess?: () => void }) => {
    controller(
      async () => {
        await fieldsApi.deleteField(id)
        if (!options?.preventGetList) {
          getListFields({ ...currentQuery, page: 1 })
        }
        message.success('Delete field successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }

  const FieldOptions = listFields?.result?.map(i => ({
    label: i.name,
    value: i.id
  }))

  return { listFields, FieldOptions, deleteFields, loading, currentQuery, detail, getListFields }
}
