import { ThemeConfig } from 'antd'

export const antdTheme: ThemeConfig = {
  token: {
    // ...colors,
    fontSizeHeading1: 38,
    fontSizeHeading2: 30,
    fontSizeHeading3: 24,
    fontSizeHeading4: 20,
    fontSizeHeading5: 16,
    fontFamily: 'SF Pro Text, sans-serif',
    borderRadius: 8
  },
  components: {
    Button: {
      // colorBgContainerDisabled: colors.grey500,
      // colorTextDisabled: colors.grey900,
      borderRadius: 99
    }
  }
}
