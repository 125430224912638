import qs from 'query-string'

import { GetWPCommonQuery, Pagination, CareerCreationParams, CareerModel } from '@types'

import { api, Api } from './config'

class Handler {
  private instance!: Api

  constructor(apiInstance: Api) {
    this.instance = apiInstance
  }

  private get caller() {
    return this.instance.caller
  }

  public getListCareer = async (params: GetWPCommonQuery) => {
    const queryString = params ? qs.stringify(params) : ''
    return this.caller.get<Pagination<CareerModel>>(`/jobs?${queryString}`)
  }

  public createNewCareer = async (params: CareerCreationParams) => {
    return this.caller.post(`/jobs`, params)
  }

  public updateCareer = async (id: string, params: CareerCreationParams) => {
    return this.caller.patch(`/jobs/${id}`, params)
  }

  public deleteCareer = async (id: string) => {
    return this.caller.delete(`/jobs/${id}`)
  }

  public getDetailCareer = async (id: string) => {
    return this.caller.get(`/jobs/${id}`)
  }
}

export const careersApi = new Handler(api)
