import { reducer as userReducer } from './user'
import { reducer as appReducer } from './app'
import { reducer as repositoryReducer } from './repository'
import { reducer as fileReducer } from './file'
import { reducer as migrationRepositoryReducer } from './migration-repository'
import { reducer as postsReducer } from './posts'
import { reducer as unitsReducer } from './units'
import { reducer as commentsReducer } from './comments'
import { reducer as fieldsReducer } from './fields'
import { reducer as careersReducer } from './career'

export const reducer = {
  app: appReducer,
  user: userReducer,
  file: fileReducer,
  repository: repositoryReducer,
  migrationRepository: migrationRepositoryReducer,
  posts: postsReducer,
  units: unitsReducer,
  comments: commentsReducer,
  fields: fieldsReducer,
  careers: careersReducer
}
