import React from 'react'
import styled from '@emotion/styled'
import moment from 'moment'

const Container = styled.div`
  display: block;
  width: 100%;
  max-width: 650px;
  text-align: left;
  font-family: Arial, sans-serif;
  font-weight: 500;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #e0e0e0;
`

const Title = styled.h3`
  text-align: left;
  color: #1a0dab;
  line-height: 21.6px;
  word-wrap: break-word;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-family: Arial, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  margin-bottom: 8px;
  padding: 0;
`

const Link = styled.span`
  display: flex;
  flex-direction: column;
  color: #000000;
  line-height: 16px;
  margin: 1px 0 0 2px;
  word-wrap: break-word;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-family: Arial, sans-serif;
  text-align: left;

  width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Description = styled.p`
  color: #545454;
  font-size: 13px;
  line-height: 18.2px;
  font-weight: 400;
  word-wrap: break-word;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;

  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Date = styled.small`
  color: #808080;
  font-size: 13px;
  line-height: 18.2px;
`
const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-right: 8px;
  }
`

const MainTitle = styled.div`
  font-size: 14px;
  color: black;
  max-width: 100%;
  overflow: hidden;
`
const Slug = styled.div`
  color: rgb(77, 81, 86);
`

interface PreviewProps {
  mainTitle?: string
  domain: string
  slug: string
  title: string
  link: string
  description: string
}

export const PreviewSearch: React.FC<PreviewProps> = data => (
  <Container>
    <Header>
      <img src='/favicon.ico' alt='placeholder' />
      <Link>
        <MainTitle>{data.mainTitle}</MainTitle>
        <Slug>{`${data.domain} › ${data.slug}`}</Slug>
      </Link>
    </Header>
    <Title>{data.title}</Title>
    <Description>
      <Date>{`${moment().format('MMM DD, YYYY')} — `}</Date>
      {data.description}
    </Description>
  </Container>
)
