'use client'
import { message } from 'antd'
import { useState } from 'react'

import { commentsApi } from '@api'
import { CommentStatusParams } from '@types'

import { useController } from '../app'

export const useCommentsActions = () => {
  const { controller } = useController()

  const [loading, setLoading] = useState(false)

  const changeStatusComment = async (id: number, params: CommentStatusParams, options?: { onSuccess?: () => void }) => {
    controller(
      async () => {
        await commentsApi.updateComment(id, params)
        message.success('Change status successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }

  return { loading, changeStatusComment }
}
