import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'
import type { TableProps } from 'antd'
import { Button, Modal, Switch, Table } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Actions, Title } from '@components'
import { DefaultCategory, ROUTERS } from '@defines'
import { useListPostsCategory } from '@hooks'
import { GetWPCommonQuery, PostCategoryModel } from '@types'

import { Header } from './styles'

const { confirm } = Modal

export const Categories = () => {
  const navigate = useNavigate()
  const { getListCategory, listCategory, deleteCategory, loading } = useListPostsCategory()

  const handleGetListCategory = (params?: GetWPCommonQuery) => {
    getListCategory({ ...params })
  }

  useEffect(() => {
    handleGetListCategory({ page: 1, limit: 10 })
  }, [])

  const columns: TableProps<PostCategoryModel>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <div>{text}</div>
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => (
        <Switch
          size='default'
          disabled
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={status}
        />
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Actions
          loading={loading}
          actions={{
            onEdit: () => {
              navigate(ROUTERS.POST_CATEGORY_DETAIL?.replace(':categoryId', record.id))
            },
            onDelete: !DefaultCategory?.includes(record?.name)
              ? async () => {
                  confirm({
                    title: 'Confirm Delete?',
                    content: 'Are you sure to procee this?',
                    onOk() {
                      deleteCategory(record.id)
                    }
                  })
                }
              : undefined
          }}
        />
      )
    }
  ]

  const data = listCategory?.result?.map(item => ({
    ...item
  }))

  const handleTableChange: TableProps<PostCategoryModel>['onChange'] = pagination => {
    handleGetListCategory({
      page: pagination.current || 1,
      limit: pagination.pageSize || 10
    })
  }

  return (
    <div>
      <Header>
        <Button type='primary' onClick={() => navigate(ROUTERS.POST_CATEGORY_CREATION)}>
          Create new <PlusOutlined />
        </Button>
      </Header>
      <Title>List category</Title>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={{
          current: Number(listCategory?.page) || 1,
          pageSize: Number(listCategory?.limit) || 10,
          total: Number(listCategory?.count) || 0
        }}
        onChange={handleTableChange}
      />
    </div>
  )
}
