import { ClusterDataType, FileBlobModel, MetaData, NetworkingDataType, RepoModel } from '@types'

import { Api, api } from './config'

class Respository {
  instance: Api

  constructor(params: Api) {
    this.instance = params
  }

  public getRepositoryRequest = (repoId: string) => {
    return this.instance.get(`/api/repository/${repoId}`)
  }

  public getFileDetailRequest = (repoId: string) => {
    return this.instance.get(`/api/blob/${repoId}`) as any as Promise<FileBlobModel>
  }

  public postRepositoryRequest = (payload: { url: string; token: string }) => {
    return this.instance.post('api/repository/', payload) as any as Promise<RepoModel>
  }

  public getClusterChartDataRequest = (repoId: string) => {
    return this.instance.get<ClusterDataType>(`/api/repository/${repoId}/clustering`)
  }

  public getGraphChartDataRequest = (repoId: string) => {
    return this.instance.get<NetworkingDataType>(`/api/repository/${repoId}/copy_graph`)
  }

  public postRepositoryMatchingRequest = (payload: { repoId: string; url: string; token?: string }) => {
    return this.instance.post(`api/repository/${payload.repoId}/matching/`, payload) as any as Promise<RepoModel>
  }

  public getMatchingScoreDataRequest = (treeId: string) => {
    return this.instance.get<MetaData>(`/api/tree/${treeId}/matching_score`)
  }

  public getComplexityDataRequest = (repoId: string) => {
    return this.instance.get(`/api/repository/${repoId}/complexity`)
  }

  public getListRepositories = () => {
    return this.instance.get(`/api/repository`)
  }

  public postTriggerRepository = (repoId: string) => {
    return this.instance.post(`/api/repository/${repoId}/export_file/`)
  }

  public getFileExcelRepository = (repoId: string, isCheckStatus?: boolean) => {
    return this.instance.get(`/api/repository/${repoId}/export_file/`, !isCheckStatus ? { responseType: 'blob' } : {})
  }

  public getBlobFile = (blobFile: string, isCheckStatus?: boolean) => {
    return this.instance.get(`/api/blob/${blobFile}/export_file/`, !isCheckStatus ? { responseType: 'blob' } : {})
  }
}

export const repositoryApi = new Respository(api)
