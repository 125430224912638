import { LoginParams } from '@types'

import { Api, api } from './config'

class UserApi {
  instance: Api

  constructor(params: Api) {
    this.instance = params
  }

  public doLoginRequest = (params: LoginParams) => {
    return this.instance.post(`/auth/login`, params)
  }
}

export const userApi = new UserApi(api)
