'use client'
import { message } from 'antd'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { careersApi } from '@api'
import { careersActions, careersSelector } from '@store'
import { CareerCreationParams } from '@types'

import { useController } from '../app'

export const useCareersActions = () => {
  const dispatch = useDispatch()
  const detail = useSelector(careersSelector.selectCareerDetail)
  const { controller } = useController()

  const [loading, setLoading] = useState(false)

  const createCareer = async (params: CareerCreationParams, options?: { onSuccess?: () => void }) => {
    controller(
      async () => {
        await careersApi.createNewCareer(params)
        message.success('Created career successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }
  const updateCareer = async (id: string, params: CareerCreationParams, options?: { onSuccess?: () => void }) => {
    controller(
      async () => {
        await careersApi.updateCareer(id, params)
        message.success('Updated career successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }

  const getDetailCareer = async (id: string, options?: { onSuccess?: () => void }) => {
    controller(
      async () => {
        const res = await careersApi.getDetailCareer(id)

        dispatch(careersActions.setCareerDetail(res?.data))
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }

  const clearDetail = () => {
    dispatch(careersActions.setCareerDetail(undefined))
  }

  return { loading, detail, clearDetail, getDetailCareer, createCareer, updateCareer }
}
