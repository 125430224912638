'use client'
import { message } from 'antd'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { unitsApi } from '@api'
import { unitsActions, unitsSelector } from '@store'
import { ProjectCreationParams } from '@types'

import { useController } from '../app'

export const useProjectsActions = () => {
  const dispatch = useDispatch()
  const detail = useSelector(unitsSelector.selectProjectDetail)

  const { controller } = useController()

  const [loading, setLoading] = useState(false)

  const createProject = async (params: ProjectCreationParams, options?: { onSuccess?: () => void }) => {
    controller(
      async () => {
        await unitsApi.createNewProject(params)
        message.success('Created project successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }
  const updateProject = async (id: string, params: ProjectCreationParams, options?: { onSuccess?: () => void }) => {
    controller(
      async () => {
        await unitsApi.updateProject(id, params)
        message.success('Updated Project successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }

  const getDetailProject = async (id: string, options?: { onSuccess?: () => void }) => {
    controller(
      async () => {
        const res = await unitsApi.getDetailProject(id)

        dispatch(unitsActions.setProjectDetail(res?.data))
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }

  const clearDetail = () => {
    dispatch(unitsActions.setProjectDetail(undefined))
  }

  return { loading, detail, clearDetail, getDetailProject, createProject, updateProject }
}
