import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { GetWPCommonQuery, CareerModel, CareersResponse } from '@types'

import { State } from './type'

const initialState: State = {
  listCareers: undefined,
  detailCareer: undefined,
  listCareersQueryParams: { limit: 10, page: 1 }
}

export const slice = createSlice({
  name: 'careers',
  initialState,
  reducers: {
    clean: () => {},
    setCareers: (state, action: PayloadAction<CareersResponse>) => {
      state.listCareers = action.payload
    },
    setCareerDetail: (state, action: PayloadAction<CareerModel | undefined>) => {
      state.detailCareer = action.payload
    },
    setCareersQuery: (state, action: PayloadAction<GetWPCommonQuery>) => {
      state.listCareersQueryParams = action.payload
    }
  }
})

// > Export
// * Action
export const actions = { ...slice.actions }
// * Reducer
export const { reducer } = slice
