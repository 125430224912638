'use client'
import { message } from 'antd'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { postApi } from '@api'
import { PostStatus } from '@defines'
import { postsActions, postsSelector } from '@store'
import { ChooseOrderPostParams, GetWPCommonQuery, UpdateOrderPostsParams } from '@types'

import { useController } from '../app'

export const useListPosts = () => {
  const dispatch = useDispatch()
  const listPosts = useSelector(postsSelector.selectPosts)
  const detail = useSelector(postsSelector.selectPostDetail)
  const currentQuery = useSelector(postsSelector.selectListPostsQueryParams)
  const { controller } = useController()

  const [loading, setLoading] = useState(false)

  const getListPosts = async (params: GetWPCommonQuery) => {
    controller(
      async () => {
        setLoading(true)
        const res = await postApi.getListPosts({ ...currentQuery, ...params })
        dispatch(postsActions.setPosts(res?.data))
        dispatch(postsActions.setPostsQuery({ ...currentQuery, ...params }))
        setLoading(false)
      },
      {
        onLoading: setLoading
      }
    )
  }

  const deletePost = async (id: string, options?: { preventGetList?: boolean; onSuccess?: () => void }) => {
    controller(
      async () => {
        await postApi.deletePost(id)
        if (!options?.preventGetList) {
          getListPosts({ ...currentQuery, page: 1 })
        }
        message.success('Delete post successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }

  const publishPost = async (id: string, options?: { preventGetList?: boolean; onSuccess?: () => void }) => {
    controller(
      async () => {
        const data = await postApi.updatePost(id, {
          status: PostStatus.Publish
        })
        dispatch(postsActions.updatePost(data?.data))
        if (!options?.preventGetList) {
          getListPosts(currentQuery)
        }
        message.success('Publish post successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }
  const makeAsDraftPost = async (id: string, options?: { preventGetList?: boolean; onSuccess?: () => void }) => {
    controller(
      async () => {
        const data = await postApi.updatePost(id, {
          status: PostStatus.Draft
        })
        dispatch(postsActions.updatePost(data?.data))
        if (!options?.preventGetList) {
          getListPosts(currentQuery)
        }
        message.success('Make as draft for post successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }
  const clearFilter = () => {
    dispatch(postsActions.setPostsQuery({ ...currentQuery, search: undefined, tag_ids: undefined }))
  }

  const updatePostsOrder = async (params: UpdateOrderPostsParams, options?: { onSuccess?: () => void }) => {
    controller(
      async () => {
        await postApi.updatePostsOrder(params)
        message.success('Updated post order successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }

  const chooseOrder = async (
    params: ChooseOrderPostParams,
    options?: { preventGetList?: boolean; onSuccess?: () => void }
  ) => {
    controller(
      async () => {
        await postApi.chooseOrder(params)
        if (!options?.preventGetList) {
          getListPosts(currentQuery)
        }
        message.success('Updated post order successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }

  return {
    listPosts,
    clearFilter,
    deletePost,
    publishPost,
    makeAsDraftPost,
    loading,
    currentQuery,
    detail,
    getListPosts,
    updatePostsOrder,
    chooseOrder
  }
}
