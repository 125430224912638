import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CategoriesResponse, GetWPCommonQuery, PostCategoryModel, PostModel, PostsResponse } from '@types'

import { State } from './type'

const initialState: State = {
  listPosts: undefined,
  detailPost: undefined,
  listCategory: undefined,
  detailCategory: undefined,
  listCategoryQueryParams: { limit: 10, page: 1 },
  listPostsQueryParams: { limit: 10, page: 1 }
}

export const slice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    clean: () => {},
    setPosts: (state, action: PayloadAction<PostsResponse>) => {
      state.listPosts = action.payload
    },
    setPostDetail: (state, action: PayloadAction<PostModel | undefined>) => {
      state.detailPost = action.payload
    },
    updatePost: (state, action: PayloadAction<PostModel | undefined>) => {
      const index = state.listPosts?.result?.findIndex(i => i.id === action.payload?.id)
      if (index !== -1 && state?.listPosts?.result) {
        state.listPosts.result[index || 0] = action.payload as PostModel
      }
    },
    setPostsQuery: (state, action: PayloadAction<GetWPCommonQuery>) => {
      state.listPostsQueryParams = action.payload
    },
    setCategories: (state, action: PayloadAction<CategoriesResponse>) => {
      state.listCategory = action.payload
    },
    setDetailCategory: (state, action: PayloadAction<PostCategoryModel | undefined>) => {
      state.detailCategory = action.payload
    },
    setCategoryQuery: (state, action: PayloadAction<GetWPCommonQuery>) => {
      state.listCategoryQueryParams = action.payload
    }
  }
})

// > Export
// * Action
export const actions = { ...slice.actions }
// * Reducer
export const { reducer } = slice
