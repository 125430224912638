'use client'
import { message } from 'antd'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { unitsApi } from '@api'
import { unitsActions, unitsSelector } from '@store'
import { IndustryCreationParams } from '@types'

import { useController } from '../app'

export const useIndustriesActions = () => {
  const dispatch = useDispatch()
  const detail = useSelector(unitsSelector.selectIndustryDetail)

  const { controller } = useController()

  const [loading, setLoading] = useState(false)

  const createIndustry = async (params: IndustryCreationParams, options?: { onSuccess?: () => void }) => {
    controller(
      async () => {
        await unitsApi.createNewIndustry(params)
        message.success('Created industry successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }
  const updateIndustry = async (id: string, params: IndustryCreationParams, options?: { onSuccess?: () => void }) => {
    controller(
      async () => {
        await unitsApi.updateIndustry(id, params)
        message.success('Updated industry successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }

  const getDetailIndustry = async (id: string, options?: { onSuccess?: () => void }) => {
    controller(
      async () => {
        const res = await unitsApi.getDetailIndustry(id)

        dispatch(unitsActions.setIndustryDetail(res?.data))
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }

  const clearDetail = () => {
    dispatch(unitsActions.setIndustryDetail(undefined))
  }

  return { loading, detail, clearDetail, getDetailIndustry, createIndustry, updateIndustry }
}
