import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { userApi, api } from '@api'
import { ROUTERS, storage } from '@defines'
import { useController } from '@hooks'
import { userActions } from '@store'
import { parseJwt } from '@utils'
import { LoginParams } from '@types'

export const useLogin = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { controller } = useController()

  const doLogin = async (params: { accessToken: string; refreshToken: string; id: string }) => {
    controller(
      async () => {
        const { accessToken, refreshToken } = params
        setLoading(true)
        dispatch(userActions.setAccessToken(accessToken))
        dispatch(userActions.setRefreshToken(refreshToken))
        localStorage.setItem(storage.ACCESS_TOKEN, String(accessToken))
        localStorage.setItem(storage.REFRESH_TOKEN, String(refreshToken))
        api.setToken(accessToken)
        const userInfo = parseJwt(accessToken)
        dispatch(userActions.setData(userInfo))
        localStorage.setItem(storage.USER_INFO, JSON.stringify(userInfo))
        navigate(ROUTERS.HOME)
      },
      {
        onDone: () => setLoading(false)
      }
    )
  }

  const handleLogin = async (params: LoginParams) => {
    controller(
      async () => {
        setLoading(true)
        const res = await userApi.doLoginRequest(params)
        doLogin({
          accessToken: res?.access_token,
          refreshToken: res?.refresh_token,
          id: res?.id
        })
      },
      {
        onDone: () => setLoading(false)
      }
    )
  }

  return { handleLogin, doLogin, loading }
}
