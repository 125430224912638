import styled from '@emotion/styled'
import { FC } from 'react'

import { PostStatus } from '@defines'
import { showStatusPost } from '@utils'

const StatusStyled = styled.div<{ status: PostStatus }>`
  color: ${({ status, theme }) => {
    switch (status) {
      case PostStatus.Draft:
        return theme.colors.warning
      case PostStatus.Publish:
        return theme.colors.success
      default:
        return theme.colors.black
    }
  }};
`
export const StatusBadge: FC<{ status: PostStatus }> = ({ status }) => {
  return <StatusStyled status={Number(status)}>{showStatusPost(status)}</StatusStyled>
}
