// > POST
export const workspace = {
  WORKSPACE: '/',
  POSTS: '/posts',
  POST_DETAIL: '/posts/:postId',
  POST_CREATION: '/posts/creation',
  POST_CATEGORY: '/posts/category',
  POST_CATEGORY_DETAIL: '/posts/category/:categoryId',
  POST_CATEGORY_CREATION: '/posts/category/creation',
  TAGS: '/tag',
  TAGS_DETAIL: '/tag/:tagId',
  TAGS_CREATION: '/tag/creation',
  CAREERS: '/career',
  CAREER_DETAIL: '/career/:careerId',
  CAREER_CREATION: '/career/creation',
  COMMENTS: '/comment',
  FIELDS: '/field',
  FIELDS_DETAIL: '/field/:fieldId',
  FIELDS_CREATION: '/field/creation',
  PROJECT: '/project',
  PROJECT_DETAIL: '/project/:projectId',
  PROJECT_CREATION: '/project/creation',
  INDUSTRY: '/industry',
  INDUSTRY_DETAIL: '/industry/:industryId',
  INDUSTRY_CREATION: '/industry/creation'
}

export const settings = {
  SETTING: '/account',
  SETTING_PROJECT: '/project',
  SETTING_PREFERENCES: '/preferences',
  SETTING_USER: '/user',
  SETTING_USAGED_TOKEN: '/usaged-token',
  SETTING_MEASUREMENT: '/:projectId/measurement'
}

const workspaceRouters: Record<keyof typeof workspace, string> = {} as any
Object.keys(workspace).forEach(key => ((workspaceRouters as any)[key] = `/workspace${(workspace as any)[key]}`))

const settingRouters: Record<keyof typeof settings, string> = {} as any
Object.keys(settings).forEach(key => ((settingRouters as any)[key] = `/settings${(settings as any)[key]}`))

const mainRouters = {
  HOME: '/',
  OGANIZATION: '/ogn/:ognId/*',
  PROFILE: '/profile',
  SETUP: '/setup',
  SURVEY: '/survey',
  LOGIN: '/signin',
  REGISTER: '/signup',
  REGISTER_ORGANIZATION: '/signup-organization',
  RECOVER: '/recover',
  INVITATION: '/accept-invite',
  FORGOT_PASSWORD: '/forgot-password',
  VERIFY: '/verify',
  VERIFY_OTP: '/verify-otp',
  RESET_PASSWORD: '/reset',
  CHANGE_PASSWORD: '/change_password',
  UNAUTHORIZED: '/401',
  NOT_FOUND: '/404',
  SERVER_ERROR: '/500',
  COMMING_SOON: '/coming-soon',
  UNDER_MAINTENANCE: '/under-maintenance',
  PRIVACY_POLICY: '/privacy-and-policy',
  OAUTH_JIRA: '/oauth-jira',
  THEME: '/theme'
}

export const ROUTERS = {
  ...mainRouters,
  ...workspaceRouters,
  ...settingRouters
}

export const ROUTERS_GUARD = [ROUTERS.POSTS]
