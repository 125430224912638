import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { ENVIRONMENT, storage } from '@defines'
import { appActions } from '@store'

export const useApp = () => {
  const dispatch = useDispatch()
  const [inited, setInited] = useState(false)

  const initEnvironment = () => {
    const environment = localStorage.getItem(storage.ENVIRONMENT)
    dispatch(appActions.setDevelopMode(environment === ENVIRONMENT.DEVELOPMENT))
  }

  const initialize = () => {
    try {
      initEnvironment()
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
    } finally {
      setInited(true)
    }
  }

  useEffect(() => {
    initialize()
  }, [])

  return { inited }
}
