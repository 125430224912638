import { createSelector } from '@reduxjs/toolkit'

const selectSelf = (state: RootState) => state.posts

export const selector = {
  selectPosts: createSelector(selectSelf, state => state.listPosts),
  selectPostDetail: createSelector(selectSelf, state => state.detailPost),
  selectListPostsQueryParams: createSelector(selectSelf, state => state.listPostsQueryParams),
  selectCategory: createSelector(selectSelf, state => state.listCategory),
  selectCategoryDetail: createSelector(selectSelf, state => state.detailCategory),
  selectListCategoryQueryParams: createSelector(selectSelf, state => state.listCategoryQueryParams)
}
