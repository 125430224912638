import { Form, FormInstance, Modal, Select } from 'antd'
import { useEffect } from 'react'

type TOrder = {
  value: number
  label: number
}

type TOrderModalProps = {
  visible: boolean
  onCancel: () => void
  onOk: (values: any) => void
  currentOrder?: number
  orders: TOrder[]
  form: FormInstance
}

export const OrderModal = ({ visible, onCancel, onOk, currentOrder, orders, form }: TOrderModalProps) => {
  useEffect(() => {
    if (visible) {
      form.resetFields()
    }
  }, [visible])

  const hasErrors = () => {
    const fieldsError = form.getFieldsError()
    return fieldsError.some(({ errors }) => errors.length > 0)
  }

  return (
    <Modal
      title='Select Order Position'
      open={visible}
      onCancel={onCancel}
      onOk={() => {
        form.validateFields().then(values => {
          form.resetFields()
          onOk(values)
        })
      }}
      okButtonProps={{
        disabled: hasErrors()
      }}
    >
      <Form form={form}>
        <Form.Item
          label='Order Position'
          name='order'
          rules={[{ required: true, message: 'Please select an order position!' }]}
        >
          <Select>
            {orders.map(order => (
              <Select.Option key={order.value} value={order.value} disabled={order.value === currentOrder}>
                {order.label} {order.value === currentOrder ? '(Current)' : ''}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}
