import { useState } from 'react'
import { Tooltip as TooltipUI, TooltipProps } from 'antd'
import styled from '@emotion/styled'

import { NetworkingDataType } from '@types'

type NodeType = NetworkingDataType['nodes'][number]

const Tooltip = styled(TooltipUI)``

export const useChartTooltip = () => {
  const [tooltipProps, setTooltipProps] = useState<TooltipProps>({})

  const toggleTooltip = (e: MouseEvent, d: NodeType, open: boolean) => {
    setTooltipProps({
      open,
      title: d.name,
      overlayStyle: {
        top: e.y + 20,
        left: e.x - 20
      }
    })
  }

  const onHoverInNode = (e: MouseEvent, d: NodeType) => toggleTooltip(e, d, true)

  const onHoverOutNode = (e: MouseEvent, d: NodeType) => toggleTooltip(e, d, false)

  const render = () => <Tooltip rootClassName='chartTooltip' arrow={false} placement='bottom' {...tooltipProps} />

  return {
    onHoverInNode,
    onHoverOutNode,
    render
  }
}
