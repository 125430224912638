import { createSelector } from '@reduxjs/toolkit'

const selectSelf = (state: RootState) => state.units

export const selector = {
  selectTags: createSelector(selectSelf, state => state.listTags),
  selectTagDetail: createSelector(selectSelf, state => state.detailTag),
  selectListTagsQueryParams: createSelector(selectSelf, state => state.listTagsQueryParams),
  selectProjects: createSelector(selectSelf, state => state.listProjects),
  selectProjectDetail: createSelector(selectSelf, state => state.detailProject),
  selectListProjectsQueryParams: createSelector(selectSelf, state => state.listProjectsQueryParams),
  selectIndustries: createSelector(selectSelf, state => state.listIndustries),
  selectIndustryDetail: createSelector(selectSelf, state => state.detailIndustry),
  selectListIndustriesQueryParams: createSelector(selectSelf, state => state.listIndustriesQueryParams)
}
