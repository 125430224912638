export const storage = {
  SETTING: 'SETTING',
  ENVIRONMENT: 'ENVIRONMENT',
  IS_VERIFIED: 'IS_VERIFIED',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  CLIENT_ID: 'CLIENT_ID',
  DEVICE_ID: 'DEVICE_ID',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  API_KEY: 'API_KEY',
  USER_INFO: 'USER_INFO',
  API_TOKEN: 'API_TOKEN',
  LOGGED_JIRA: 'LOGGED_JIRA',
  JIRA_RESOURCE: 'JIRA_RESOURCE'
}
