import { Tag, TagProps } from 'antd'
import { FC } from 'react'

type CustomTag = {
  name: string
  id: string
}
type Props = {
  tags?: CustomTag[]
}

const LIST_TAG_COLOR: TagProps['color'][] = ['success', 'error', 'warning', 'default', 'processing']
const FIXED_COLOR: { [key: string]: TagProps['color'] } = {
  publication: 'blue',
  techblogs: 'cyan',
  news: 'geekblue'
}

export const CustomTagList: FC<Props> = ({ tags }) => {
  if (!tags?.length) {
    return <>Empty</>
  }
  const renderList = () => {
    return tags?.map((tag: CustomTag, index: number) => {
      const color = FIXED_COLOR[String(tag.name).toLocaleLowerCase()] || LIST_TAG_COLOR?.[index] || 'blue'
      return (
        <Tag color={color} key={tag.id}>
          {tag?.name}
        </Tag>
      )
    })
  }
  return <>{renderList()}</>
}
