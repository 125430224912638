import styled from '@emotion/styled'
import { Typography } from 'antd'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 61px 48px;
  border-radius: 24px;
  background: ${({ theme }) => theme.colors.primary200};

  & > *:not(:last-child) {
    margin-right: 70px;
  }
`

export const FigSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:last-child) {
    margin-bottom: 18px;
  }
`

export const FormSide = styled.div`
  display: flex;
  align-items: center;

  form {
    width: 400px;
  }
`

export const WrapFig = styled.div`
  padding: 24px;
  border-radius: 100px 33px 39px 12px;
  background: ${({ theme }) => theme.colors.primary100};
`

export const Title = styled(Typography)`
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  color: #fff;
  letter-spacing: 0em;
`

export const FormItemSubmit = styled.div`
  display: flex;
  justify-content: end;
`
