import { Button, Result } from 'antd'
import { Link } from 'react-router-dom'

import MainLayout from '@layouts/MainLayout'
import logoPng from '@assets/pngs/logo.png'
import { ROUTERS } from '@defines'

export const HomePage = () => {
  return (
    <MainLayout>
      <Result
        icon={
          <div
            style={{
              background: '#292E30',
              padding: 20
            }}
          >
            <img src={logoPng} alt='logo' />
          </div>
        }
        title="Welcome to AIC CMS. Let's view your posts"
        extra={
          <Link to={ROUTERS.POSTS}>
            <Button type='primary'>Visit posts</Button>
          </Link>
        }
      />
    </MainLayout>
  )
}
