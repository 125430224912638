import { CheckOutlined, CloseOutlined, RollbackOutlined } from '@ant-design/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Col, Form, Row, Switch } from 'antd'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

import { CustomInput, Label, Title } from '@components'
import { ROUTERS } from '@defines'
import { useController, useTagsActions } from '@hooks'
import { TagCreationParams } from '@types'

import { FormItemSubmit, Header } from './styles'

export const DetailTags = () => {
  const { tagId } = useParams()
  const { createTag, getDetailTag, detail, clearDetail, loading, updateTag } = useTagsActions()
  const { t } = useTranslation()
  const { controller } = useController()
  const navigate = useNavigate()
  const schema = yup.object().shape({
    name: yup.string().trim().required(t('message.error_required')),
    description: yup.string().trim().required(t('message.error_required')),
    status: yup.boolean().required(t('message.error_required'))
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      status: true
    },
    resolver: yupResolver(schema)
  })

  const handleForm = async (formData: TagCreationParams) =>
    controller(async () => {
      const onSuccess = () => {
        navigate(ROUTERS.TAGS)
      }
      if (tagId) {
        updateTag(tagId, formData, { onSuccess })
      } else {
        createTag({ ...formData }, { onSuccess })
      }
    })

  useEffect(() => {
    // setValues("title",)
    if (tagId) {
      getDetailTag(tagId)
    }
    return () => {
      clearDetail()
    }
  }, [tagId])

  useEffect(() => {
    if (detail) {
      setValue('name', detail?.name || '')
      setValue('description', detail?.description || '')
    }
  }, [detail])

  const onSubmit = handleSubmit(handleForm)

  return (
    <div>
      <Header>
        <Button onClick={() => navigate(ROUTERS.TAGS)}>
          <RollbackOutlined />
          Back
        </Button>
      </Header>
      <Title>Detail Tag</Title>
      <div>
        <Form
          name='normal_login'
          className='login-form'
          initialValues={{
            remember: true
          }}
          onFinish={onSubmit}
        >
          <Row gutter={[20, 20]}>
            <Col xs={8}>
              <CustomInput
                control={control}
                type='string'
                name='name'
                placeholder='Name'
                label='Name'
                required
                maxLength={500}
                error={errors.name?.message}
                size='large'
                mb={12}
              />
            </Col>
            <Col xs={8}>
              <CustomInput
                control={control}
                type='string'
                name='description'
                placeholder='Description'
                label='Description'
                required
                maxLength={500}
                error={errors.description?.message}
                size='large'
                mb={12}
              />
            </Col>
            <Col xs={8}>
              <Label>Status</Label>
              <Switch
                size='default'
                disabled
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked
              />
            </Col>
          </Row>
          <FormItemSubmit>
            <Button type='primary' size='large' loading={loading} htmlType='submit' className='save-draft'>
              Save
            </Button>
          </FormItemSubmit>
        </Form>
      </div>
    </div>
  )
}
