import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import type { TableProps } from 'antd'
import { Switch, Table } from 'antd'
import { useEffect } from 'react'

import { Title } from '@components'
import { useListComments, useCommentsActions } from '@hooks'
import { GetWPCommonQuery, CommentModel } from '@types'

export const Comments = () => {
  const { getListComments, listComments, loading } = useListComments()
  const { changeStatusComment } = useCommentsActions()

  const handleGetListComment = (params?: GetWPCommonQuery) => {
    getListComments({ ...params })
  }

  useEffect(() => {
    handleGetListComment({ page: 1, limit: 10 })
  }, [])

  const onChangeSwitch = (checked: boolean, commentId: number) => {
    changeStatusComment(commentId, { status: checked ? 1 : 0 })
  }

  const columns: TableProps<any>['columns'] = [
    {
      title: 'Comment',
      dataIndex: 'message',
      key: 'comment',
      render: text => <div>{text}</div>
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (field, rest) => <div>{rest?.anonymous_user?.name}</div>
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (field, rest) => <div>{rest?.anonymous_user?.email}</div>
    },
    {
      title: 'Website',
      dataIndex: 'website',
      key: 'website',
      render: (field, rest) => <div>{rest?.anonymous_user?.website}</div>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (field, rest) => (
        <Switch
          size='default'
          disabled={loading}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={!!Number(rest?.status)}
          onChange={e => onChangeSwitch(e, rest?.id)}
        />
      )
    }
  ]

  const data = listComments?.result?.map(item => ({
    ...item
  }))

  const handleTableChange: TableProps<CommentModel>['onChange'] = pagination => {
    handleGetListComment({
      page: pagination.current || 1,
      limit: pagination.pageSize || 10
    })
  }

  return (
    <div>
      <Title>List comments</Title>
      <Table
        columns={columns}
        loading={loading}
        dataSource={data}
        pagination={{
          current: Number(listComments?.page) || 1,
          pageSize: Number(listComments?.limit) || 10,
          total: Number(Math.ceil(Number(listComments?.count) / Number(listComments?.limit))) || 0
        }}
        onChange={handleTableChange}
      />
    </div>
  )
}
