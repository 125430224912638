'use client'
import { message } from 'antd'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { postApi } from '@api'
import { postsActions, postsSelector } from '@store'
import { PostCreationParams } from '@types'

import { useController } from '../app'

export const usePostAction = () => {
  const dispatch = useDispatch()
  const detail = useSelector(postsSelector.selectPostDetail)

  const { controller } = useController()

  const [loading, setLoading] = useState(false)

  const createPost = async (params: PostCreationParams, options?: { onSuccess?: () => void }) => {
    controller(
      async () => {
        await postApi.createNewPost(params)
        message.success('Created post successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading,
        onError: (err: any) => {
          if (typeof err?.response?.data?.message === 'string') {
            message.error(err?.response?.data?.message)
          }
        }
      }
    )
  }
  const updatePost = async (id: string, params: PostCreationParams, options?: { onSuccess?: () => void }) => {
    controller(
      async () => {
        await postApi.updatePost(id, params)
        message.success('Updated post successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }

  const getDetailPost = async (id: string, options?: { onSuccess?: () => void }) => {
    controller(
      async () => {
        const res = await postApi.getDetailPost(id)

        dispatch(postsActions.setPostDetail(res?.data))
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }

  const clearDetail = () => {
    dispatch(postsActions.setPostDetail(undefined))
  }

  return { loading, detail, clearDetail, getDetailPost, createPost, updatePost }
}
