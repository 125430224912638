import qs from 'query-string'

import {
  ChooseOrderPostParams,
  GetWPCommonQuery,
  Pagination,
  PostCategoryCreationForm,
  PostCategoryModel,
  PostCreationParams,
  PostModel,
  UpdateOrderPostsParams,
  UploadImageRes
} from '@types'

import { api, Api } from './config'

class Handler {
  private instance!: Api

  constructor(apiInstance: Api) {
    this.instance = apiInstance
  }

  private get caller() {
    return this.instance.caller
  }

  public getListPosts = async (params: GetWPCommonQuery) => {
    const queryString = params ? qs.stringify(params, { arrayFormat: 'bracket' }) : ''
    return this.caller.get<Pagination<PostModel>>(`/post?${queryString}`)
  }

  public createNewPost = async (params: PostCreationParams) => {
    return this.caller.post(`/post`, params)
  }

  public updatePost = async (id: string, params: PostCreationParams) => {
    return this.caller.patch(`/post/${id}`, params)
  }

  public deletePost = async (id: string) => {
    return this.caller.delete(`/post/${id}`)
  }

  public getDetailPost = async (id: string) => {
    return this.caller.get(`/post/${id}`)
  }

  public updatePostsOrder = async (params: UpdateOrderPostsParams) => {
    return this.caller.put(`/post/order`, params)
  }

  public chooseOrder = async (params: ChooseOrderPostParams) => {
    return this.caller.put(`/post/choose-order`, params)
  }

  public getListPostsTech = async (params: GetWPCommonQuery) => {
    const queryString = params ? qs.stringify(params) : ''
    return this.caller.get<PostModel[]>(`/tech?${queryString}`)
  }

  public uploadPostImage = async (params: FormData) => {
    return this.caller.post<UploadImageRes>(`/image`, params)
  }

  public uploadPostFile = async (params: FormData) => {
    return this.caller.post<UploadImageRes>(`/file`, params)
  }

  public getListPostCategory = async (params: GetWPCommonQuery) => {
    const queryString = params ? qs.stringify(params) : ''
    return this.caller.get<Pagination<PostCategoryModel>>(`/category?${queryString}`)
  }

  public deleteCategory = async (id: string) => {
    return this.caller.delete(`/category/${id}`)
  }

  public getDetailPostCategory = async (id: string) => {
    return this.caller.get(`/category/${id}`)
  }

  public createNewCategory = async (params: PostCategoryCreationForm) => {
    return this.caller.post(`/category`, params)
  }

  public updateCategory = async (id: string, params: PostCategoryCreationForm) => {
    return this.caller.patch(`/category/${id}`, params)
  }
}

export const postApi = new Handler(api)
