export { ReactComponent as LogoNameSvg } from './logo-name.svg'
export { ReactComponent as LogoSvg } from './logo.svg'
export { ReactComponent as SearchSvg } from './search.svg'
export { ReactComponent as CorporateSvg } from './corporate.svg'
export { ReactComponent as OrganizationSvg } from './organization.svg'
export { ReactComponent as FrameCoverSvg } from './frame-cover.svg'
export { ReactComponent as ShieldSvg } from './shield.svg'
export { ReactComponent as EditSvg } from './edit.svg'
export { ReactComponent as LogOutSvg } from './log-out.svg'
export { ReactComponent as MigrationSvg } from './migration.svg'
export { ReactComponent as PlusSvg } from './plus.svg'
export { ReactComponent as RepositorySvg } from './repository.svg'
export { ReactComponent as CobolListSvg } from './cobol-list.svg'
export { ReactComponent as SettingsSvg } from './settings.svg'
export { ReactComponent as ArrowRightSvg } from './arrow-right.svg'
export { ReactComponent as ArrowLeftSvg } from './arrow-left.svg'
export { ReactComponent as ArrowUpSvg } from './arrow-up.svg'
export { ReactComponent as ArrowDownSvg } from './arrow-down.svg'
export { ReactComponent as FolderSvg } from './folder.svg'
export { ReactComponent as RootSvg } from './root.svg'
export { ReactComponent as DividerSvg } from './divider.svg'
export { ReactComponent as MenuSvg } from './menu.svg'
export { ReactComponent as ShareSvg } from './share.svg'
export { ReactComponent as ArrowHighPrioritySvg } from './arrow-hight-priority.svg'
export { ReactComponent as ArrowLowPrioritySvg } from './arrow-low-priority.svg'
export { ReactComponent as ArrowMediumPrioritySvg } from './arrow-medium-priority.svg'
export { ReactComponent as GaugeBaseSvg } from './gauge-base.svg'
export { ReactComponent as ArrowGaugeSvg } from './gauge-arrow.svg'
