import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  GetWPCommonQuery,
  IndustriesResponse,
  IndustryModel,
  ProjectModel,
  ProjectsResponse,
  TagModel,
  TagsResponse
} from '@types'

import { State } from './type'

const initialState: State = {
  listTags: undefined,
  detailTag: undefined,
  listTagsQueryParams: { limit: 10, page: 1 },
  listProjects: undefined,
  detailProject: undefined,
  listProjectsQueryParams: { limit: 10, page: 1 },
  listIndustries: undefined,
  detailIndustry: undefined,
  listIndustriesQueryParams: { limit: 10, page: 1 }
}

export const slice = createSlice({
  name: 'units',
  initialState,
  reducers: {
    clean: () => {},
    setTags: (state, action: PayloadAction<TagsResponse>) => {
      state.listTags = action.payload
    },
    setTagDetail: (state, action: PayloadAction<TagModel | undefined>) => {
      state.detailTag = action.payload
    },
    setTagsQuery: (state, action: PayloadAction<GetWPCommonQuery>) => {
      state.listTagsQueryParams = action.payload
    },
    setProjects: (state, action: PayloadAction<ProjectsResponse>) => {
      state.listProjects = action.payload
    },
    setProjectDetail: (state, action: PayloadAction<ProjectModel | undefined>) => {
      state.detailProject = action.payload
    },
    setProjectsQuery: (state, action: PayloadAction<GetWPCommonQuery>) => {
      state.listProjectsQueryParams = action.payload
    },
    setIndustries: (state, action: PayloadAction<IndustriesResponse>) => {
      state.listIndustries = action.payload
    },
    setIndustryDetail: (state, action: PayloadAction<IndustryModel | undefined>) => {
      state.detailIndustry = action.payload
    },
    setIndustriesQuery: (state, action: PayloadAction<GetWPCommonQuery>) => {
      state.listIndustriesQueryParams = action.payload
    }
  }
})

// > Export
// * Action
export const actions = { ...slice.actions }
// * Reducer
export const { reducer } = slice
