import { Suspense, lazy } from 'react'
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'

import { GlobalLoading } from '@components'
import { ROUTERS } from '@defines'
import HomePage from '@pages/home'

import { EmptyRouter } from './EmptyRouter'
import { useRouteRender } from './hooks'
import { WorkspaceRouter } from './WorkspaceRouter'

const LoginPage = lazy(() => import('@pages/login'))
const RegisterPage = lazy(() => import('@pages/register'))
const ForgotPasswordPage = lazy(() => import('@pages/forgotPassword'))
const VerifyPage = lazy(() => import('@pages/verify'))
const ResetPasswordPage = lazy(() => import('@pages/resetPassword'))
const NotFoundPage = lazy(() => import('@pages/notfound'))
const ServerErrorPage = lazy(() => import('@pages/serverError'))
const UnderMaintenancePage = lazy(() => import('@pages/underMaintenance'))
const PrivacyPolicyPage = lazy(() => import('@pages/privacyPolicy'))
const ThemePage = lazy(() => import('@pages/theme'))

export const Router: React.FC = () => {
  const router = createBrowserRouter([
    {
      path: ROUTERS.HOME,
      element: useRouteRender(<HomePage />)
    },
    {
      path: ROUTERS.LOGIN,
      element: useRouteRender(<LoginPage />, { nonAuthen: true })
    },
    {
      path: `${ROUTERS.WORKSPACE}*`,
      element: useRouteRender(<WorkspaceRouter />, { nonAuthen: true })
    },
    {
      path: '*',
      element: <Navigate to={ROUTERS.NOT_FOUND} />
    },
    {
      path: ROUTERS.REGISTER,
      element: useRouteRender(<RegisterPage />, { nonAuthen: true })
    },
    {
      path: ROUTERS.RESET_PASSWORD,
      element: useRouteRender(<ResetPasswordPage />, { nonAuthen: true })
    },

    {
      path: ROUTERS.VERIFY,
      element: useRouteRender(<VerifyPage />)
    },
    {
      path: ROUTERS.FORGOT_PASSWORD,
      element: useRouteRender(<ForgotPasswordPage />, { nonAuthen: true })
    },
    {
      path: ROUTERS.SERVER_ERROR,
      element: useRouteRender(<ServerErrorPage />)
    },
    {
      path: ROUTERS.UNDER_MAINTENANCE,
      element: useRouteRender(<UnderMaintenancePage />)
    },
    {
      path: ROUTERS.PRIVACY_POLICY,
      element: useRouteRender(<PrivacyPolicyPage />)
    },
    {
      path: ROUTERS.THEME,
      element: useRouteRender(<ThemePage />)
    },
    {
      path: ROUTERS.NOT_FOUND,
      element: useRouteRender(<NotFoundPage />)
    },
    {
      path: '/',
      element: useRouteRender(<EmptyRouter />)
    },
    {
      path: '*',
      element: <Navigate to={ROUTERS.NOT_FOUND} />
    }
  ])
  return (
    // <BrowserRouter>
    <Suspense fallback={<GlobalLoading />}>
      <RouterProvider router={router} />
    </Suspense>
    // </BrowserRouter>
  )
}
