import { CheckOutlined, CloseOutlined, RollbackOutlined } from '@ant-design/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Col, Form, Row, Switch } from 'antd'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

import { CustomInput, Label, Title } from '@components'
import { DefaultCategory, ROUTERS } from '@defines'
import { useController, usePostCategoryActions } from '@hooks'
import { PostCategoryCreationForm } from '@types'

import { FormItemSubmit, Header } from './styles'

export const DetailCategory = () => {
  const { categoryId } = useParams()
  const { createCategory, getDetailCategory, detail, clearDetail, loading, updateCategory } = usePostCategoryActions()

  const { t } = useTranslation()
  const { controller } = useController()
  const navigate = useNavigate()
  const schema = yup.object().shape({
    name: yup.string().trim().required(t('message.error_required')),
    description: yup.string().trim().required(t('message.error_required')),
    status: yup.boolean().required(t('message.error_required'))
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      status: true
    },
    resolver: yupResolver(schema)
  })

  const handleForm = async (formData: PostCategoryCreationForm) =>
    controller(async () => {
      const onSuccess = () => {
        navigate(ROUTERS.POST_CATEGORY)
      }
      if (categoryId) {
        updateCategory(categoryId, formData, { onSuccess })
      } else {
        createCategory(formData, { onSuccess })
      }
    })

  useEffect(() => {
    // setValues("title",)
    if (categoryId) {
      getDetailCategory(categoryId)
    }
    return () => {
      clearDetail()
    }
  }, [categoryId])

  useEffect(() => {
    if (detail) {
      setValue('name', detail?.name || '')
      setValue('description', detail?.description || '')
      setValue('status', detail?.status || false)
    }
  }, [detail])

  const onSubmit = handleSubmit(handleForm)

  return (
    <div>
      <Header>
        <Button onClick={() => navigate(ROUTERS.POST_CATEGORY)}>
          <RollbackOutlined />
          Back
        </Button>
      </Header>
      <Title>Detail Category</Title>
      <div>
        <Form
          name='normal_login'
          className='login-form'
          initialValues={{
            remember: true
          }}
          onFinish={onSubmit}
          // onKeyDown={e => {
          //   if (e.code === 'Enter') {
          //     const values: PostCreation = getValues()
          //     handleSubmit(() => handleForm(values))
          //   }
          // }}
        >
          <Row gutter={[20, 20]}>
            <Col xs={8}>
              <CustomInput
                control={control}
                type='string'
                name='name'
                placeholder='Name'
                disabled={DefaultCategory?.includes(String(detail?.name))}
                label='Name'
                required
                maxLength={500}
                error={errors.name?.message}
                size='large'
                mb={12}
              />
            </Col>
            <Col xs={8}>
              <CustomInput
                control={control}
                type='string'
                name='description'
                placeholder='Description'
                label='Description'
                required
                maxLength={500}
                error={errors.description?.message}
                size='large'
                mb={12}
              />
            </Col>
            <Col xs={8}>
              <Label>Status</Label>
              <Switch
                size='default'
                disabled
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked
              />
            </Col>
          </Row>
          <FormItemSubmit>
            <Button type='primary' size='large' loading={loading} htmlType='submit' className='save-draft'>
              Save
            </Button>
          </FormItemSubmit>
        </Form>
      </div>
    </div>
  )
}
