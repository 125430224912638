import { RcFile } from 'antd/es/upload'
import slugify from 'slugify'

import { CategoriesFixed, FIXED_PERMANENT_LINK, PostStatus } from '@defines'

export const capitalize = (text: string) => {
  let arr = text.split(' ')
  arr = arr.map(o => o[0].toUpperCase() + o.slice(1))
  return arr.join(' ')
}

export const checkURL = (text: string) => {
  return /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/.test(
    text
  )
}

export const parseJwt = (token: string) => {
  if (!token) {
    return null
  }
  const base64Url = token?.split('.')?.[1]
  const base64 = base64Url?.replace(/-/g, '+')?.replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(c => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => {
    return callback(reader.result as string)
  })
  return reader.readAsDataURL(img)
}

export const handleUrlImg = (url?: string) => {
  if (!url || typeof url !== 'string') {
    return ''
  }
  if (url?.includes('http')) {
    return url
  }
  return `${process.env.REACT_APP_STORE_IMAGE_URL}${url}`
}

export const showStatusPost = (status: number) => {
  return PostStatus?.[status]
}

export const commonSlug = (slug: string) => {
  if (!slug) {
    return ''
  }
  return slugify(slug, {
    lower: true,
    trim: true,
    strict: true
  })
}

export const validateUrl = (url: string) => {
  return /(http(s?)):\/\//i.test(url)
}

export const autoGeneratePermanentLink = (category?: string, slug?: string) => {
  if (category === CategoriesFixed.News) {
    return `${FIXED_PERMANENT_LINK.NEWS}/${slug || ''}`
  }
  if (category === CategoriesFixed.Publication) {
    return `${FIXED_PERMANENT_LINK.PUBLICATION}/${slug || ''}`
  }
  if (category === CategoriesFixed.Techblogs) {
    return `${FIXED_PERMANENT_LINK.TECH_BLOGS}/${slug || ''}`
  }
  if (category === CategoriesFixed['Webinars & Workshops']) {
    return `${FIXED_PERMANENT_LINK.WEBINARS}/${slug || ''}`
  }
  return process.env.REACT_APP_MAIN_PAGE || ''
}
