'use client'
import { message } from 'antd'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { unitsApi } from '@api'
import { unitsActions, unitsSelector } from '@store'
import { GetWPCommonQuery } from '@types'

import { useController } from '../app'

export const useListTags = () => {
  const dispatch = useDispatch()
  const listTags = useSelector(unitsSelector.selectTags)
  const detail = useSelector(unitsSelector.selectTagDetail)
  const currentQuery = useSelector(unitsSelector.selectListTagsQueryParams)

  const { controller } = useController()

  const [loading, setLoading] = useState(false)

  const getListTags = async (params: GetWPCommonQuery) => {
    controller(
      async () => {
        setLoading(true)
        const res = await unitsApi.getListTags({ ...currentQuery, ...params })

        dispatch(unitsActions.setTags(res?.data))
        dispatch(unitsActions.setTagsQuery({ ...currentQuery, ...params }))
        setLoading(false)
      },
      {
        onLoading: setLoading
      }
    )
  }

  const deleteTags = async (id: string, options?: { preventGetList?: boolean; onSuccess?: () => void }) => {
    controller(
      async () => {
        await unitsApi.deleteTag(id)
        if (!options?.preventGetList) {
          getListTags({ ...currentQuery, page: 1 })
        }
        message.success('Delete tag successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }

  const tagOptions = listTags?.result?.map(i => ({
    label: i.name,
    value: i.id
  }))

  return { listTags, tagOptions, deleteTags, loading, currentQuery, detail, getListTags }
}
