import { Col, Collapse, Divider, Form, Input, Row, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { UploadFile, UploadProps } from 'antd/es/upload'

import { PostSEO } from '@types'
import { commonSlug } from '@utils'
import { useUpload } from '@hooks'

import { FormItem } from './styles'
import { PreviewSearch } from './PreviewSearch'
import { PreviewSocial } from './PreviewSocial'

interface SeoConfigProps {
  headerTitle?: string
  onChange?: (seo: PostSEO) => void
  initialSeo?: PostSEO
}

export const SeoConfig: React.FC<SeoConfigProps> = ({ headerTitle = 'SEO Configuration', onChange, initialSeo }) => {
  const [seo, setSeo] = useState<PostSEO>({})
  const [fileList, setFileList] = useState<UploadFile[]>()
  const { handleUpload } = useUpload()
  const [form] = Form.useForm()

  useEffect(() => {
    if (initialSeo) {
      setSeo(initialSeo)
      form.setFieldsValue({
        title: initialSeo?.title,
        description: initialSeo?.description
      })
      setFileList(
        initialSeo.ogn_img?.length
          ? initialSeo?.ogn_img?.map((i, index) => ({
              uid: i.id,
              name: `SEO-image-${index}`,
              path: i.path,
              thumbUrl: i.path
            }))
          : undefined
      )
    }
  }, [initialSeo])

  useEffect(() => {
    onChange?.(seo)
  }, [])

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const onChangeSeo = (name: string, value: string) => {
    setSeo(prev => ({ ...prev, [name]: value }))
  }

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList)
  }

  return (
    <Collapse>
      <Collapse.Panel header={<b>{headerTitle}</b>} key={headerTitle}>
        <Form layout='vertical' form={form}>
          <FormItem label='Title' name='title' help='This should be between 50 and 60 characters'>
            <Input value={seo?.title} onChange={e => onChangeSeo('title', e.target.value)} />
          </FormItem>

          <FormItem label='Description' name='description'>
            <Input.TextArea value={seo?.description} onChange={e => onChangeSeo('description', e.target.value)} />
          </FormItem>

          <FormItem label='Social Media Appearance' valuePropName='meta_image' getValueFromEvent={normFile}>
            <Upload
              onChange={handleChange}
              fileList={fileList}
              listType='picture-card'
              beforeUpload={e => {
                handleUpload(e)
                return false
              }}
              maxCount={1}
            >
              <button style={{ border: 0, background: 'none' }} type='button'>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Select</div>
              </button>
            </Upload>
          </FormItem>
        </Form>
        <Divider />
        <Row gutter={[20, 20]}>
          <Col xs={12}>
            <h4>Search Preview</h4>

            <PreviewSearch
              mainTitle={'FPT Software AI Center'}
              domain='aic-center.com'
              slug={commonSlug(seo?.title || '') || 'post-slug'}
              title={seo?.title || 'Post Title'}
              link='https://fpt-aicenter.com'
              description={
                seo?.description ||
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Autem optio perferendis repudiandae numquam minus explicabo tempora sed mollitia a, iste facere maxime reiciendis ipsum animi quo consequatur et distinctio deleniti!'
              }
            />
          </Col>
          <Col xs={12}>
            <h4>Social Share Preview</h4>
            <PreviewSocial
              srcImg={fileList?.[0]?.thumbUrl || 'https://via.placeholder.com/150'}
              domain='aic-center.com'
              title={seo?.title || 'Post Title'}
              description={
                seo?.description ||
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Autem optio perferendis repudiandae numquam minus explicabo tempora sed mollitia a, iste facere maxime reiciendis ipsum animi quo consequatur et distinctio deleniti!'
              }
            />
          </Col>
        </Row>
      </Collapse.Panel>
    </Collapse>
  )
}
