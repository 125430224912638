import qs from 'query-string'

import { GetWPCommonQuery, Pagination, CommentModel, CommentStatusParams } from '@types'

import { api, Api } from './config'

class Handler {
  private instance!: Api

  constructor(apiInstance: Api) {
    this.instance = apiInstance
  }

  private get caller() {
    return this.instance.caller
  }

  public getListComments = async (params: GetWPCommonQuery) => {
    const queryString = params ? qs.stringify(params) : ''
    return this.caller.get<Pagination<CommentModel>>(`/comment?${queryString}`)
  }

  public updateComment = async (id: number, params: CommentStatusParams) => {
    return this.caller.patch(`/comment/${id}`, params)
  }
}

export const commentsApi = new Handler(api)
