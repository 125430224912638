import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 650px;
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
    sans-serif;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #e0e0e0;
`

const Body = styled.div`
  padding: 10px 12px;
  background: #f2f3f5;
`

const Domain = styled.div`
  color: #606770;
  font-size: 12px;
  text-transform: uppercase;
`

const Title = styled.h3`
  font-size: 16px;
  color: #1d2129;
  word-wrap: break-word;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
`

const Description = styled.div`
  color: #606770;

  word-wrap: break-word;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
const Thumbnail = styled.img`
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
`

interface PreviewProps {
  srcImg: string
  domain: string
  title: string
  description: string
}

export const PreviewSocial: React.FC<PreviewProps> = data => (
  <Container>
    <Thumbnail src={data?.srcImg || 'https://via.placeholder.com/150'} alt='thumbnail' />
    <Body>
      <Domain>{data.domain}</Domain>
      <Title>{data.title}</Title>
      <Description>{data.description}</Description>
    </Body>
  </Container>
)
