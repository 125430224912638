import { PostCreationForm, PostSEO, ThumnailImageModel } from '@types'

export const populateSeo = (
  postParams: PostCreationForm & { thumnail?: ThumnailImageModel[] },
  seo: PostSEO
): PostSEO => {
  return {
    title: seo.title || postParams?.title || '',
    description: seo.description || postParams?.excerpt || '',
    ogn_img: seo.ogn_img || postParams?.thumnail || []
  }
}
