import qs from 'query-string'

import {
  GetWPCommonQuery,
  IndustryCreationParams,
  IndustryModel,
  Pagination,
  ProjectCreationParams,
  ProjectModel,
  TagCreationParams,
  TagModel
} from '@types'

import { api, Api } from './config'

class Handler {
  private instance!: Api

  constructor(apiInstance: Api) {
    this.instance = apiInstance
  }

  private get caller() {
    return this.instance.caller
  }

  public getListTags = async (params: GetWPCommonQuery) => {
    const queryString = params ? qs.stringify(params) : ''
    return this.caller.get<Pagination<TagModel>>(`/tag?${queryString}`)
  }

  public createNewTag = async (params: TagCreationParams) => {
    return this.caller.post(`/tag`, params)
  }

  public updateTag = async (id: string, params: TagCreationParams) => {
    return this.caller.patch(`/tag/${id}`, params)
  }

  public deleteTag = async (id: string) => {
    return this.caller.delete(`/tag/${id}`)
  }

  public getDetailTag = async (id: string) => {
    return this.caller.get(`/tag/${id}`)
  }

  public getListProjects = async (params: GetWPCommonQuery) => {
    const queryString = params ? qs.stringify(params) : ''
    return this.caller.get<Pagination<ProjectModel>>(`/projects?${queryString}`)
  }

  public createNewProject = async (params: ProjectCreationParams) => {
    return this.caller.post(`/projects`, params)
  }

  public updateProject = async (id: string, params: ProjectCreationParams) => {
    return this.caller.patch(`/projects/${id}`, params)
  }

  public deleteProject = async (id: string) => {
    return this.caller.delete(`/projects/${id}`)
  }

  public getDetailProject = async (id: string) => {
    return this.caller.get(`/projects/${id}`)
  }

  public getListIndustries = async (params: GetWPCommonQuery) => {
    const queryString = params ? qs.stringify(params) : ''
    return this.caller.get<Pagination<IndustryModel>>(`/industry?${queryString}`)
  }

  public createNewIndustry = async (params: IndustryCreationParams) => {
    return this.caller.post(`/industry`, params)
  }

  public updateIndustry = async (id: string, params: IndustryCreationParams) => {
    return this.caller.patch(`/industry/${id}`, params)
  }

  public deleteIndustry = async (id: string) => {
    return this.caller.delete(`/industry/${id}`)
  }

  public getDetailIndustry = async (id: string) => {
    return this.caller.get(`/industry/${id}`)
  }
}

export const unitsApi = new Handler(api)
