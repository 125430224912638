import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Button, Layout, Menu } from 'antd'
import React, { ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { MAIN_MENU } from '@defines'
import logoPng from '@assets/pngs/logo.png'

import { ProfileBadge } from './components'

const { Header, Sider, Content } = Layout

const WrapContent = styled(Content)`
  min-height: calc(100vh - 64px);
  max-height: calc(100vh - 64px);
  overflow: auto;
  .content {
    padding: 24px;
  }
`
const WrapHeader = styled(Header)`
  display: flex;
  align-items: center;
  color: white;
  justify-content: space-between;
  padding-left: 0px;
  button {
    color: white;
  }
`
// const WrapBreadcrumb = styled(Breadcrumb)`
//   border-bottom: solid 1px #d9d9d9;
//   padding: 4px;
// `

const WrapLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  img {
    width: 60px;
  }
`

type Props = {
  children: ReactNode
}

export const MainLayout: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate()
  const [collapsed, setCollapsed] = useState(false)

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <WrapLogo>
          <img src={logoPng} alt='logo' />
        </WrapLogo>
        <Menu theme='dark' mode='inline' defaultSelectedKeys={['1']} onClick={e => navigate(e.key)} items={MAIN_MENU} />
      </Sider>
      <Layout>
        <WrapHeader>
          <Button
            type='text'
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
          />
          <ProfileBadge />
        </WrapHeader>
        <WrapContent>
          {/* <WrapBreadcrumb>
            <Breadcrumb.Item>User</Breadcrumb.Item>
            <Breadcrumb.Item>Bill</Breadcrumb.Item>
          </WrapBreadcrumb> */}
          <div className='content'>{children}</div>
        </WrapContent>
      </Layout>
    </Layout>
  )
}
