import { message } from 'antd'
import QuillResizeImage from 'quill-resize-image'
import React, { useEffect } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import ImageUploader from 'quill-image-uploader'
import katex from 'katex'
import styled from '@emotion/styled'

import { UploadImageRes } from '@types'
import { clearAllStorage } from '@utils'
import { api } from '@api'
import { RESPONSE_CODE, ROUTERS } from '@defines'

import { QuillToolbar, formats } from './EditorToolbar'

import 'react-quill/dist/quill.snow.css'
import 'katex/dist/katex.min.css'

window.katex = katex

const Wrap = styled.div`
  position: relative;
  max-height: 600px;
  scroll-behavior: smooth;
  overflow: auto;
  .ql-editor {
    min-height: 200px;
    background: white;
    .ql-video {
      min-width: 700px;
      min-height: 400px;
    }
  }
  .toolbar {
    z-index: 1001;
  }
  .label {
    margin-bottom: 8px;
  }
  .error {
    margin-right: 8px;
    color: ${({ theme }) => theme.colors.danger};
  }
`

const Label = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  .required {
    margin-right: 8px;
    color: ${({ theme }) => theme.colors.danger};
  }
`

type Props = {
  value: string
  label?: string
  error?: string
  required?: boolean
  onChange: (value: string) => void
}

// Add sizes to whitelist and register them
const Size = Quill.import('formats/size')
Size.whitelist = ['extra-small', 'small', 'medium', 'large']
Quill.register(Size, true)

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font')
Font.whitelist = ['arial', 'comic-sans', 'courier-new', 'georgia', 'helvetica', 'lucida']
Quill.register(Font, true)

// image handle
// Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/resize', QuillResizeImage)

// #2 register module
Quill.register('modules/imageUploader', ImageUploader)

let contentScrollTopPosition = 0

// Import the base image format from Quill
const BaseImageFormat = Quill.import('formats/image')
const BaseVideoFormat = Quill.import('formats/video')

// Define the list of image format attributes
const ImageFormatAttributesList: string[] = ['alt', 'height', 'width', 'style']

// Create a custom ImageFormat class that extends the base image format
class ImageFormat extends BaseImageFormat {
  // Static method to get the format attributes from a DOM node
  static formats(domNode: HTMLElement): Record<string, string> {
    return ImageFormatAttributesList.reduce<Record<string, string>>((format, attribute) => {
      if (domNode.hasAttribute(attribute)) {
        format[attribute] = domNode.getAttribute(attribute) || ''
      }
      return format
    }, {})
  }

  // Method to format the image attributes
  format(name: string, value: string): void {
    if (ImageFormatAttributesList.includes(name)) {
      if (value) {
        this.domNode.setAttribute(name, value)
      } else {
        this.domNode.removeAttribute(name)
      }
    } else {
      super.format(name, value)
    }
  }
}

class CustomVideo extends BaseVideoFormat {
  static create(value: any) {
    const node = super.create(value)
    node.setAttribute('width', node.getAttribute('width') || '560')
    node.setAttribute('height', node.getAttribute('height') || '315')
    node.setAttribute('frameborder', node.getAttribute('frameborder') || '0')
    node.setAttribute('allowfullscreen', node.getAttribute('allowfullscreen') || true)

    // Add responsive styles
    let style = node.getAttribute('style') || ''
    style += 'max-width: 100%; height: auto; min-height: 315px'
    node.setAttribute('style', style)
    return node
  }
}

// Register the custom ImageFormat with Quill
Quill.register(ImageFormat, true)
Quill.register(CustomVideo, true)

let quillEditor: any

// Modules object for setting up the Quill editor
const modules = {
  toolbar: {
    container: '#toolbar',
    scrollingContainer: 'body',
    handlers: {
      undo: () => {
        return quillEditor?.history.undo()
      },
      redo: () => {
        return quillEditor?.history.redo()
      }
    }
  },
  imageUploader: {
    upload: (file: any) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('file', file)
        api
          .post(`${process.env.REACT_APP_API_URL}/image`, formData)
          .then(res => {
            if (res as UploadImageRes) {
              resolve(res?.path)
            } else {
              reject(`Couldn't upload file: ${file.name}.`)
            }
          })
          .catch(err => {
            // eslint-disable-line no-debugger
            if (err?.response?.status === RESPONSE_CODE.UNAUTHORIZED) {
              message.error('Token is expired')
              clearAllStorage()
              window.location.href = ROUTERS.LOGIN
            } else {
              resolve('')
              message.error('Something went wrong when upload image.')
            }
          })
      })
    }
  },
  resize: {
    locale: {
      center: 'center'
    }
  },

  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
}

export const QuillEditor: React.FC<Props> = ({ value, onChange, error, label, required }) => {
  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      e.preventDefault()
    }

    const pickers = document.querySelectorAll<HTMLDivElement>('.ql-picker')
    pickers.forEach(picker => {
      picker.addEventListener('mousedown', handleMouseDown)
    })
    const actionsQl = document.querySelectorAll<HTMLDivElement>('.quill-toolbar')
    actionsQl.forEach(picker => {
      picker.addEventListener('mousedown', handleMouseDown)
    })

    // Cleanup the event listeners when the component unmounts
    return () => {
      pickers.forEach(picker => {
        picker.removeEventListener('mousedown', handleMouseDown)
      })
      actionsQl.forEach(picker => {
        picker.removeEventListener('mousedown', handleMouseDown)
      })
    }
  }, [])

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        if (contentScrollTopPosition) {
          const contentE = document.querySelector('.ant-layout-content')
          setTimeout(() => {
            contentE?.scrollTo({
              top: contentScrollTopPosition
            })
          }, 1)
        }
      }
    }

    const tooltip = document.querySelector('.ql-tooltip')
    if (tooltip) {
      ;(tooltip as any)?.addEventListener('keydown', handleKeyPress)
    }

    return () => {
      if (tooltip) {
        ;(tooltip as any)?.removeEventListener('keydown', handleKeyPress)
      }
    }
  }, [])

  return (
    <div>
      <Label>
        {required && <span className='required'>*</span>}
        {label}
      </Label>
      <Wrap className='wrap-quill' id='wrap-quill'>
        <QuillToolbar
        // isStickyItem={isStickyItem}
        />

        {modules ? (
          <ReactQuill
            theme='snow'
            scrollingContainer='#wrap-quill'
            ref={el => {
              if (el) quillEditor = el.getEditor()
            }}
            value={value || ''}
            onChange={e => {
              onChange(e)
            }}
            onKeyDown={e => {
              if (e.code === 'KeyV') {
                const contentE = document.querySelector('.ant-layout-content')
                contentScrollTopPosition = contentE?.scrollTop || 0
              }
            }}
            placeholder={'Write something awesome...'}
            modules={modules}
            bounds={'.wrap-quill'}
            formats={formats}
          />
        ) : null}
        {error ? <div className='error'>{error}</div> : null}
      </Wrap>
    </div>
  )
}
