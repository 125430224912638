import styled from '@emotion/styled'
import { Button } from 'antd'
import { FC } from 'react'

export const WrapActions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  .action {
    cursor: pointer;
    margin-bottom: 16px;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  .delete-btn {
    border: solid;
    border-color: ${({ theme }) => theme.colors.danger};
    color: ${({ theme }) => theme.colors.danger};
  }
  .publish-btn {
    border: solid;
    border-color: ${({ theme }) => theme.colors.info};
    color: ${({ theme }) => theme.colors.info};
  }
  .draft-btn {
    border: solid;
    border-color: ${({ theme }) => theme.colors.warning};
    color: ${({ theme }) => theme.colors.warning};
  }
  .order-btn {
    border: solid;
    border-color: ${({ theme }) => theme.colors.green};
    color: ${({ theme }) => theme.colors.green};
  }
`

type Props = {
  loading?: boolean
  actions: {
    onEdit?: () => void
    onDelete?: () => void
    onPublish?: () => void
    onOrder?: () => void
    makeAsDraft?: () => void
  }
}
export const Actions: FC<Props> = ({ actions, loading }) => {
  return (
    <WrapActions>
      {actions?.onEdit && (
        <Button loading={loading} className='action' onClick={actions?.onEdit}>
          Edit
        </Button>
      )}
      {actions?.onPublish && (
        <Button loading={loading} className='action publish-btn' onClick={actions?.onPublish}>
          Publish
        </Button>
      )}
      {actions?.makeAsDraft && (
        <Button loading={loading} className='action draft-btn' onClick={actions?.makeAsDraft}>
          Make as Draft
        </Button>
      )}
      {actions?.onOrder && (
        <Button loading={loading} className='action order-btn' onClick={actions?.onOrder}>
          Order
        </Button>
      )}
      {actions?.onDelete && (
        <Button loading={loading} className='action delete-btn' onClick={actions?.onDelete}>
          Delete
        </Button>
      )}
    </WrapActions>
  )
}
