import { PostStatus } from '@defines'
import { PostCreationForm, PostCreationParams } from '@types'
import { autoGeneratePermanentLink, commonSlug, formatDateApi, populateSeo } from '@utils'

export const handleParamsPost = ({
  formData,
  imageUploaded,
  setErrorTHum,
  setErrorContent,
  content,
  fileUploaded,
  selectedCate,
  socialLinks,
  seoForm,
  detail,
  scrollToError,
  publishDate
}: any) => {
  const {
    status,
    permalink,
    post_categories: postCategories,
    tags,
    post_industries: postIndustries,
    fields,
    title,
    slug,
    excerpt,
    used_technologies: usedTechnologies,
    ...rest
  } = formData as PostCreationForm

  let isError = false
  if (!imageUploaded?.id) {
    setErrorTHum('Thumnail image is required.')
    isError = true
  }
  if (!content) {
    setErrorContent('Content is required.')
    isError = true
  }
  if (isError) {
    scrollToError()
    return
  }
  const seo = populateSeo({ ...formData, thumnail: imageUploaded ? [imageUploaded] : [] }, seoForm)

  const params: PostCreationParams = {
    ...rest,
    title,
    slug: slug ? slug : commonSlug(title),
    content,
    excerpt,
    permalink: permalink?.includes('fpt-aicenter.com')
      ? autoGeneratePermanentLink(selectedCate?.label, slug)
      : permalink,
    post_thumbnail_images: imageUploaded?.id ? [imageUploaded?.id] : [],
    status: status || Number(detail?.status) || PostStatus.Draft,
    social_links: socialLinks,
    post_categories: postCategories || [],
    post_tags: tags || undefined,
    post_fields: fields || undefined,
    seo,
    post_files: fileUploaded?.id ? [fileUploaded?.id] : undefined,
    post_industries: postIndustries || undefined,
    used_technologies: usedTechnologies || undefined,
    publish_date: formatDateApi(publishDate)
  }

  return params
}

export const handleErrorPost = ({ errors, errorImage, errorContent }: any) => {
  const errorKeys = Object.keys(errors)
  if (errorKeys.length > 0) {
    const firstErrorKey = errorKeys[0]
    const errorElement = document.querySelector(`[name="${firstErrorKey}"]`)
    const errorElementById = document.getElementById(firstErrorKey)
    if (errorElement) {
      errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      return
    }
    if (errorElementById) {
      errorElementById?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      return
    }
  }
  if (errorImage) {
    const errorImageE = document.getElementById(`error-image`)
    errorImageE?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    return
  }
  if (errorContent) {
    const errorE = document.getElementById(`error-content`)
    errorE?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    return
  }
}
