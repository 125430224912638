import styled from '@emotion/styled'
import { FC, ReactNode } from 'react'

const TitleStyled = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
`

export const Title: FC<{ children?: ReactNode | string }> = ({ children }) => {
  return <TitleStyled>{children}</TitleStyled>
}
