import { PropsWithChildren, ReactNode, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ROUTERS } from '@defines'
import { userSelector } from '@store'

import { Options as OptionsRouteRender, useRouteRender } from './useRouteRender'

const Container: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const userData = useSelector(userSelector.selectData)
  const { location } = window

  useEffect(() => {
    if (!userData) {
      const search = new URLSearchParams()
      if (location.pathname !== '/' && location.pathname !== ROUTERS.LOGIN)
        search.append('redirect', location.href.replace(location.origin, ''))
      const queries = search.toString()

      navigate(`${ROUTERS.LOGIN}${queries ? `?${queries}` : ''}`)
    }
  }, [userData])

  return <>{children}</>
}

export const useGuard = (node: ReactNode, options?: OptionsRouteRender) => {
  return <Container>{useRouteRender(node, options)}</Container>
}
