import { PlusOutlined } from '@ant-design/icons'
import type { TableProps } from 'antd'
import { Button, Modal, Table } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Actions, Title } from '@components'
import { ROUTERS } from '@defines'
import { useListProjects } from '@hooks'
import { GetWPCommonQuery, ProjectModel } from '@types'

import { Header } from './styles'

const { confirm } = Modal

export const Projects = () => {
  const navigate = useNavigate()
  const { getListProjects, listProjects, deleteProjects, loading } = useListProjects()

  const handleGetListProject = (params?: GetWPCommonQuery) => {
    getListProjects({ ...params })
  }

  useEffect(() => {
    handleGetListProject({ page: 1, limit: 10 })
  }, [])

  const columns: TableProps<any>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <div>{text}</div>
    },
    {
      title: 'Industry',
      dataIndex: 'industries',
      key: 'industries',
      render: text => <div>{text?.map((i: any) => i.name)?.join(', ') || ''}</div>
    },
    {
      title: 'Used Technologies',
      dataIndex: 'used_technologies',
      key: 'used_technologies'
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Actions
          loading={loading}
          actions={{
            onEdit: () => {
              navigate(ROUTERS.PROJECT_DETAIL?.replace(':projectId', record.id))
            },
            onDelete: async () => {
              confirm({
                title: 'Confirm Delete?',
                content: 'Are you sure to proceed this?',
                onOk() {
                  deleteProjects(record.id)
                }
              })
            }
          }}
        />
      )
    }
  ]

  const data = listProjects?.result?.map(item => ({
    ...item
  }))

  const handleTableChange: TableProps<ProjectModel>['onChange'] = pagination => {
    handleGetListProject({
      page: pagination.current || 1,
      limit: pagination.pageSize || 10
    })
  }

  return (
    <div>
      <Header>
        <Button type='primary' onClick={() => navigate(ROUTERS.PROJECT_CREATION)}>
          Create new <PlusOutlined />
        </Button>
      </Header>
      <Title>List Projects</Title>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={{
          current: Number(listProjects?.page) || 1,
          pageSize: Number(listProjects?.limit) || 10,
          total: Number(listProjects?.count) || 0
        }}
        onChange={handleTableChange}
      />
    </div>
  )
}
