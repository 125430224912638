import { CheckOutlined, CloseOutlined, RollbackOutlined } from '@ant-design/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Col, DatePicker, Form, Row, Switch } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import { v4 as uuidv4 } from 'uuid'

import { CustomInput, CustomSelect, Label, QuillEditor, Title } from '@components'
import { ROUTERS } from '@defines'
import { useCareersActions, useController } from '@hooks'
import { CareerCreationParams } from '@types'
import { commonSlug } from '@utils'

import { FormItemSubmit, Header, Wrap, WrapPublishDate } from './styles'

type OptionModel = {
  label: string
  value: string
}

const VACACY_OPTIONS: OptionModel[] = [
  {
    label: 'Open',
    value: 'open'
  },
  {
    label: 'Close',
    value: 'close'
  }
]

const TIME_OPTIONS: OptionModel[] = [
  {
    label: 'Full-time',
    value: 'Full-time'
  },
  {
    label: 'Part-time',
    value: 'Part-time'
  }
]

const defaultContent = `<p><strong>RESPONSIBILITIES</strong></p><p><br></p><p><br></p><ol><li>something</li></ol><p><br></p><p><strong>SKILLS AND QUALIFICATIONS</strong></p><p><br></p><p><br></p><ol><li>something</li></ol><p><br></p><p><br></p><p><strong>HOW TO APPLY</strong></p><p>Interested candidates should submit their CV/Resume/Portfolio in English to email:&nbsp;<a href="mailto:giangvth1@fpt.com" rel="noopener noreferrer" target="_blank">giangvth1@fpt.com</a>,&nbsp;<a href="mailto:huongnh@fpt.com" rel="noopener noreferrer" target="_blank">huongnh@fpt.com</a></p><p>Email Title: [Full Time Negotiable] - [Your Full Name]</p>`

type SummaryModel = {
  publishOn: dayjs.Dayjs
  vacancy: string
  employeeStatus: string
  salary: string
  deadline: dayjs.Dayjs
}

export const DetailCareer = () => {
  const { careerId } = useParams()
  const [content, setContent] = useState(defaultContent)
  const [errorContent, setErrorContent] = useState('')

  const [summary, setSummary] = useState<SummaryModel>({
    publishOn: dayjs(new Date()),
    vacancy: VACACY_OPTIONS?.[0]?.value,
    employeeStatus: TIME_OPTIONS?.[0]?.value,
    salary: 'Negotiable',
    deadline: dayjs(new Date())
  })

  const {
    detail,
    clearDetail,
    loading: loadingDetail,
    createCareer,
    getDetailCareer,
    updateCareer
  } = useCareersActions()

  const { t } = useTranslation()
  const { controller } = useController()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const currentParams = Object.fromEntries([...searchParams])
  const schema = yup.object().shape({
    title: yup.string().trim().required(t('message.error_required')).min(8),
    skills: yup.string().trim().required(t('message.error_required')),
    slug: yup.string().trim().required(t('message.error_required')).min(8),
    status: yup.boolean().nullable()
  })

  const loading = loadingDetail

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
    watch,

    setError,
    setValue
  } = useForm({
    mode: 'onBlur',
    defaultValues: {},
    resolver: yupResolver(schema)
  })

  const watchTitle = watch('title')
  const watchStatus = watch('status')

  useEffect(() => {
    if (!careerId) {
      setValue('slug', commonSlug(watchTitle))
      setError('slug', { message: '' })
    }
  }, [watchTitle])

  useEffect(() => {
    if (careerId) {
      getDetailCareer(careerId)
    } else if (searchParams.get('catId')) {
    }
    return () => {
      clearDetail()
    }
  }, [careerId])

  useEffect(() => {
    if (detail) {
      setValue('title', detail?.title || '')
      setValue('slug', detail?.slug || '')
      setValue('skills', detail?.skills || '')
      setValue('status', Number(detail?.status) === 1)
      setContent(detail?.content)
      setSummary({
        ...detail?.summary,
        publishOn: dayjs(detail?.summary?.publishOn),
        deadline: detail?.summary?.deadline ? dayjs(detail?.summary?.deadline) : undefined
      })
    }
  }, [detail])

  const navigateKeepParams = (pathname: string) => {
    navigate({ pathname, search: `?${createSearchParams(currentParams)}` })
  }

  const scrollToError = () => {
    if (!isSubmitted) {
      return
    }
  }

  const handleForm = async (formData: any) =>
    controller(async () => {
      const onSuccess = () => {
        navigateKeepParams(ROUTERS.CAREERS)
      }

      if (!content) {
        setErrorContent('Content is required.')
        return
      }
      const params: CareerCreationParams = {
        title: formData?.title,
        summary,
        content,
        skills: formData?.skills,
        status: formData?.status ? 1 : 0,
        slug: formData?.slug
      }

      if (!params) {
        return
      }
      if (careerId) {
        updateCareer(careerId, params, { onSuccess })
      } else {
        createCareer({ ...params, slug: `${formData?.slug}-${uuidv4()}` }, { onSuccess })
      }
    })

  const onSubmit = handleSubmit(handleForm)

  const filterOption = (input: string, option?: { label: string; value: number }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  useEffect(() => {
    const errorKeys = Object.keys(errors)
    if (errorKeys?.length) {
      scrollToError()
    }
  }, [errors])

  const onChangeSummary = (key: keyof SummaryModel, value: any) => {
    setSummary((prev: SummaryModel) => ({
      ...prev,
      [key]: value
    }))
  }

  return (
    <Wrap>
      <Header>
        <Button onClick={() => navigateKeepParams(ROUTERS.CAREERS)}>
          <RollbackOutlined />
          Back
        </Button>
      </Header>
      <Row>
        <Col xs={18}>
          <Title>Detail Career</Title>
        </Col>
        <Col xs={6}>
          <WrapPublishDate>
            <div>Publish date</div>

            <DatePicker value={summary?.publishOn} onChange={e => onChangeSummary('publishOn', e)} />
          </WrapPublishDate>
        </Col>
      </Row>
      <div>
        <Form
          name='normal_login'
          className='login-form'
          initialValues={{
            remember: true
          }}
          onFinish={onSubmit}
          onKeyDown={e => {
            if (e.code === 'Enter') {
              e.preventDefault()
              return
              // const values: PostCreation = getValues()
              // handleSubmit(() => handleForm(values))
            }
          }}
        >
          <Row gutter={20}>
            <Col xs={12}>
              <CustomInput
                control={control}
                type='string'
                name='title'
                placeholder={`Title`}
                label='Title'
                required
                maxLength={500}
                minLength={8}
                error={errors.title?.message}
                size='large'
                mb={12}
              />
            </Col>
            <Col xs={12}>
              <CustomInput
                control={control}
                type='string'
                name='skills'
                required
                label={t('Your skills')}
                placeholder={t('Your skills separated by , or ;')}
                maxLength={500}
                error={errors.skills?.message}
                size='large'
                mb={12}
                radius={8}
              />
            </Col>
            <Col xs={12}>
              <CustomInput
                control={control}
                type='string'
                name='slug'
                disabled
                required
                label={t('Your slug')}
                maxLength={500}
                error={errors.slug?.message}
                size='large'
                mb={12}
                radius={8}
              />
            </Col>
            <Col xs={12}>
              <Label>Status</Label>
              <Switch
                size='default'
                checked={watchStatus ? true : false}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked
                onChange={e => setValue('status', e)}
              />
            </Col>
            <Col xs={24}>
              <QuillEditor
                value={content}
                label='Content'
                required
                error={errorContent}
                onChange={e => {
                  setErrorContent('')
                  setContent(e)
                }}
              />
            </Col>
          </Row>

          <h1>Summary</h1>
          <Row gutter={20}>
            <Col xs={12}>
              <CustomSelect
                id='vacancy'
                label='Vacancy'
                size='large'
                options={VACACY_OPTIONS}
                filterOption={filterOption as any}
                showSearch
                valueProps={summary?.vacancy}
                onChangeValues={e => onChangeSummary('vacancy', e)}
                mb={16}
                required
                name={`time`}
                placeholder='Select vacacy'
              />
            </Col>
            <Col xs={12}>
              <CustomSelect
                id='employment status'
                label='Employment status'
                size='large'
                options={TIME_OPTIONS}
                filterOption={filterOption as any}
                showSearch
                valueProps={summary?.employeeStatus}
                onChangeValues={e => onChangeSummary('employeeStatus', e)}
                mb={16}
                required
                // mode='multiple'
                name={`time`}
                placeholder='Select employment status'
                // error={errors.time?.message}
              />
            </Col>
            <Col xs={12}>
              <CustomInput
                type='string'
                name='salary'
                placeholder={`salary`}
                label='Salary'
                required
                maxLength={500}
                value={summary?.salary}
                onChange={e => onChangeSummary('salary', e.target.value)}
                size='large'
                mb={12}
              />
            </Col>
            <Col xs={12}>
              <WrapPublishDate>
                <div>Deadline</div>

                <DatePicker value={summary?.deadline || undefined} onChange={e => onChangeSummary('deadline', e)} />
              </WrapPublishDate>
            </Col>
          </Row>

          <FormItemSubmit>
            <Button type='primary' loading={loading} htmlType='submit' size='large'>
              Save
            </Button>
          </FormItemSubmit>
        </Form>
      </div>
    </Wrap>
  )
}
