import { FileOutlined, PlusOutlined } from '@ant-design/icons'

import { ROUTERS } from './routers'

export const MAIN_MENU = [
  {
    key: 'Posts',
    icon: <FileOutlined />,
    label: 'Posts',
    children: [
      { key: ROUTERS.POSTS, icon: <FileOutlined />, label: 'All posts' },
      { key: ROUTERS.POST_CREATION, icon: <PlusOutlined />, label: 'Add new post' }
    ]
  },
  {
    key: 'Career',
    icon: <FileOutlined />,
    label: 'Career',
    children: [
      { key: ROUTERS.CAREERS, icon: <FileOutlined />, label: 'All careers' },
      { key: ROUTERS.CAREER_CREATION, icon: <PlusOutlined />, label: 'Add new career' }
    ]
  },
  {
    key: 'Category',
    icon: <FileOutlined />,
    label: 'Category',
    children: [
      { key: ROUTERS.POST_CATEGORY, icon: <FileOutlined />, label: 'All category' },
      { key: ROUTERS.POST_CATEGORY_CREATION, icon: <PlusOutlined />, label: 'Add new category' }
    ]
  },
  {
    key: 'Tags',
    icon: <FileOutlined />,
    label: 'Tags',
    children: [
      { key: ROUTERS.TAGS, icon: <FileOutlined />, label: 'All tags' },
      { key: ROUTERS.TAGS_CREATION, icon: <PlusOutlined />, label: 'Add new tag' }
    ]
  },
  {
    key: 'Comments',
    icon: <FileOutlined />,
    label: 'Comments',
    children: [{ key: ROUTERS.COMMENTS, icon: <FileOutlined />, label: 'All comments' }]
  },
  {
    key: 'Fields',
    icon: <FileOutlined />,
    label: 'Fields',
    children: [
      { key: ROUTERS.FIELDS, icon: <FileOutlined />, label: 'All fields' },
      { key: ROUTERS.FIELDS_CREATION, icon: <PlusOutlined />, label: 'Add new field' }
    ]
  },
  {
    key: 'AI Projects',
    icon: <FileOutlined />,
    label: 'AI Projects',
    children: [
      { key: ROUTERS.PROJECT, icon: <FileOutlined />, label: 'All projects' },
      { key: ROUTERS.PROJECT_CREATION, icon: <PlusOutlined />, label: 'Add new project' }
    ]
  },
  {
    key: 'Industry',
    icon: <FileOutlined />,
    label: 'Industry',
    children: [
      { key: ROUTERS.INDUSTRY, icon: <FileOutlined />, label: 'All industry' },
      { key: ROUTERS.INDUSTRY_CREATION, icon: <PlusOutlined />, label: 'Add new industry' }
    ]
  }
]
