import { RollbackOutlined, UserOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Avatar, Dropdown, MenuProps } from 'antd'

import { useLogout } from '@hooks'

const WrapDropdown = styled(Dropdown)`
  cursor: pointer;
`

const DropDownItem = styled.div`
  color: #000;
`

export const ProfileBadge = () => {
  const { logout } = useLogout()

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <DropDownItem onClick={logout}>
          <RollbackOutlined /> Sign out
        </DropDownItem>
      )
    }
  ]
  return (
    <WrapDropdown menu={{ items }} trigger={['click']} placement='bottom'>
      <Avatar size={32} icon={<UserOutlined />} />
    </WrapDropdown>
  )
}
