import styled from '@emotion/styled'

import { colors } from '@style'

const Wrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1002;
`

const WrapLegend = styled.div<{ background?: string; highLight?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, background }) => background || theme.colors.primary100};
  margin-right: 16px;
  border-radius: 8px;
  position: relative;
  opacity: ${({ highLight }) => {
    if (highLight === false) {
      return 0.5
    }
    return 1
  }};
  z-index: ${({ highLight }) => (highLight ? 1000 : 100)};

  .icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    display: flex;
    align-items: center;
  }
  .name {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
`

export const Legend: React.FC<{
  data: {
    icon?: JSX.Element
    name: string
    backgroundColor?: string
  }[]
  highLight?: string
}> = ({ data, highLight }) => {
  return (
    <Wrap>
      {data.sort().map(item => {
        return (
          <WrapLegend
            highLight={highLight ? item?.backgroundColor === highLight : undefined}
            key={item?.name}
            background={item.backgroundColor || colors.colorWhite00}
          >
            <div className='icon'>{item.icon}</div>
            <div className='name'>{item.name}</div>
          </WrapLegend>
        )
      })}
    </Wrap>
  )
}
