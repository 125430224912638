import styled from '@emotion/styled'
import { RcFile } from 'antd/es/upload'
import React, { useState, ChangeEvent, DragEvent, useEffect, useRef } from 'react'

const Wrap = styled.div`
  .error {
    margin-right: 8px;
    color: ${({ theme }) => theme.colors.danger};
  }
`
const WrapPreviewImg = styled.div`
  border: solid 0.5px gray;
  img {
    max-height: 338px;
    width: 100%;
    object-fit: contain;
    height: 100%;
  }
`

type ImageUploaderProps = {
  children?: React.ReactElement
  src?: string
  error?: string
  onChange?: (file: File | RcFile) => void
}

const UploadArea = styled.div`
  input {
    opacity: 0;
    height: 0px !important;
  }
  display: inline-block;
`

export const UploadWrap: React.FC<ImageUploaderProps> = ({ children, error, src, onChange }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const uploadRef = useRef<HTMLInputElement>()

  useEffect(() => {
    if (src) {
      setSelectedImage(src)
    }
  }, [src])

  const previewImage = (file: File | null) => {
    if (file) {
      onChange?.(file)
      const reader = new FileReader()
      reader.onloadend = () => {
        setSelectedImage(reader.result as string)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    previewImage(file || null)
  }

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const file = event.dataTransfer.files?.[0]
    previewImage(file)
  }

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  return (
    <Wrap>
      <UploadArea onDrop={handleDrop} onDragOver={handleDragOver}>
        <div onClick={() => uploadRef?.current?.click?.()}>{children}</div>
        <input type='file' ref={uploadRef as any} accept='image/*' onChange={handleFileChange} hidden={true} />
      </UploadArea>
      {error ? <div className='error'>{error}</div> : null}
      {selectedImage && (
        <WrapPreviewImg>
          <img src={selectedImage} alt='Preview' />
        </WrapPreviewImg>
      )}
    </Wrap>
  )
}
