'use client'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'

import { postsActions, postsSelector } from '@store'
import { GetWPCommonQuery } from '@types'
import { postApi } from '@api'

import { useController } from '../app'

export const useListPostsCategory = () => {
  const dispatch = useDispatch()
  const listCategory = useSelector(postsSelector.selectCategory)
  const detail = useSelector(postsSelector.selectCategoryDetail)
  const currentQuery = useSelector(postsSelector.selectListCategoryQueryParams)
  const { controller } = useController()

  const [loading, setLoading] = useState(false)

  const getListCategory = async (params: GetWPCommonQuery, options?: { onSuccess?: () => void }) => {
    controller(
      async () => {
        setLoading(true)

        const res = await postApi.getListPostCategory({ ...currentQuery, ...params })

        dispatch(postsActions.setCategories(res?.data))
        dispatch(postsActions.setCategoryQuery({ ...currentQuery, ...params }))
        options?.onSuccess?.()
        setLoading(false)
      },
      {
        onLoading: setLoading
      }
    )
  }

  const deleteCategory = async (id: string, options?: { preventGetList?: boolean; onSuccess?: () => void }) => {
    controller(
      async () => {
        await postApi.deleteCategory(id)
        if (!options?.preventGetList) {
          getListCategory({ ...currentQuery, page: 1 })
        }
        message.success('Delete post successfully.')
        options?.onSuccess?.()
      },
      {
        onLoading: setLoading
      }
    )
  }

  const categoryOptions = listCategory?.result?.map(i => ({
    label: i.name,
    value: i.id
  }))

  return { listCategory, categoryOptions, deleteCategory, loading, currentQuery, detail, getListCategory }
}
